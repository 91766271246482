import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalSukses = ({showmodal, hidemodal, textsukses}) => {
    const pengaturanumum = JSON.parse(localStorage.getItem('pengaturanumum'));
    const url = window.location.pathname;
    const segment2 = url.split('/')[2];
    return (
        <>
            <Modal
                aria-labelledby='modalSuksesLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                show={showmodal}
                onHide={hidemodal}
                contentClassName={segment2 === 'admin' && pengaturanumum?.tema !== 'bawaan' && 'modaldark'}
            >
                <div className='modal-header modalheaderlogin'>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        <h4 className='modal-titlelogin'>Selamat!</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                            <LazyLoadImage src='/img/success.svg' alt='success' />
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                            {parse("<p className='submodal-titlelogin textsukses'>"+textsukses+"</p>")}
                        </div>
                        <div className='col-12 center-align'>
                            <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Tutup</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalSukses