import { React, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser'
import ReactStars from 'react-rating-stars-component';
import ModalSuksesComponent from '../sukses/ModalSukses';
import usePolingKoresponden from '../../../api/usePolingKoresponden';
import { potongText } from '../../../utils/Utils';
import usePolingDetail from '../../../api/usePolingDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalPolingDetail = ({ showmodal, hidemodal, id }) => {
    const auth = JSON.parse(localStorage.getItem('user'));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [getPolingDetailData, setPolingDetailData] = useState([]);
    const [polingDetailData, errorPolingDetail] = usePolingDetail(getPolingDetailData);
    const [showModalSukses, setShowModalSukses] = useState(false);
    const [textSukses, setTextSukses] = useState('');
    const [showModalDetail, setShowModalDetail] = useState(false);
    const [polingKorespondenData, setPolingKorespondenData] = useState([]);
    const [errorPolingKoresponden, successPolingKoresponden] = usePolingKoresponden(polingKorespondenData);
    const [countSukses, setCountSukses] = useState(0);

    useEffect(() => {
        setShowModalDetail(showmodal);
        if (showmodal === true) {
            if (id !== 0) {
                const data = {
                    id: id,
                }
                setPolingDetailData(data);
            }
        }
        setCountSukses(0);
        setSuccessMessage('');
        setErrorMessage('');
        setSelectedJawabanPeriksaFakta([]);
        setSelectedJawabanSurvey([]);
    }, [showmodal, id]);

    //Voting
    const submitVoting = (e, idpolings, idpolingdetails, judul) => {
        e.preventDefault();
        const polingkorespondendata = {
            token: auth?.data?.token,
            polings_id: idpolings,
            koresponden_poling: [{
                poling_details_id: idpolingdetails,
                jawaban_jumlah: '1',
                jawaban: judul,
            }]
        }
        setPolingKorespondenData(polingkorespondendata);
        setTextSukses('Terima kasih sudah memberikan penilaian');
    }

    //Bintang
    const submitRating = (jawaban, idpolings, idpolingdetails) => {
        const polingkorespondendata = {
            token: auth?.data?.token,
            polings_id: idpolings,
            koresponden_poling: [{
                poling_details_id: idpolingdetails,
                jawaban_jumlah: '' + jawaban,
                jawaban: '',
            }]
        }
        setPolingKorespondenData(polingkorespondendata);
        setTextSukses('Terima kasih sudah memberikan penilaian');
    }

    //Periksa Fakta
    const [selectedJawabanPeriksaFakta, setSelectedJawabanPeriksaFakta] = useState([]);
    const handleRadioPeriksaFakta = (pilihanId, pertanyaanId, namapilihan) => {
        const values = [...selectedJawabanPeriksaFakta];
        values[pilihanId] = { poling_details_id: pertanyaanId, jawaban_jumlah: '1', jawaban: namapilihan };
        setSelectedJawabanPeriksaFakta(values);
    }
    const submitPeriksaFakta = (e, idpolings) => {
        const polingkorespondendata = {
            token: auth?.data?.token,
            polings_id: idpolings,
            koresponden_poling: selectedJawabanPeriksaFakta
        }
        setPolingKorespondenData(polingkorespondendata);
        setTextSukses('Terima kasih sudah memberikan penilaian');
    }

    //Survey
    const [selectedJawabanSurvey, setSelectedJawabanSurvey] = useState([]);
    const handleRadioSurvey = (pilihanId, pertanyaanId, namapilihan) => {
        const values = [...selectedJawabanSurvey];
        values[pilihanId] = { poling_details_id: pertanyaanId, jawaban_jumlah: '1', jawaban: namapilihan };
        setSelectedJawabanSurvey(values);
    };
    const submitSurvey = (e, idpolings) => {
        e.preventDefault();

        const polingkorespondendata = {
            token: auth?.data?.token,
            polings_id: idpolings,
            koresponden_poling: selectedJawabanSurvey
        }
        setPolingKorespondenData(polingkorespondendata);
        setTextSukses('Terima kasih sudah memberikan penilaian');
    }

    const hideModalSukses = () => {
        setShowModalSukses(false);
        hidemodal();
    }

    useEffect(() => {
        if (errorPolingDetail !== '') {
            setErrorMessage(errorPolingDetail);
            setCountSukses(0);
            setSelectedJawabanPeriksaFakta([]);
            setSelectedJawabanSurvey([]);
        } else if (errorPolingKoresponden !== '') {
            setErrorMessage(errorPolingKoresponden);
            setCountSukses(0);
            setSelectedJawabanPeriksaFakta([]);
            setSelectedJawabanSurvey([]);
        }
    }, [errorPolingDetail, errorPolingKoresponden]);

    useEffect(() => {
        if (successPolingKoresponden !== '') {
            setSuccessMessage(successPolingKoresponden);
            setCountSukses(1);
        }
    }, [successPolingKoresponden]);

    useEffect(() => {
        if (countSukses === 1 && errorMessage === '' && successMessage !== '') {
            setShowModalDetail(false);
            setShowModalSukses(true);
            setCountSukses(0);
            setSuccessMessage('');
            setErrorMessage('');
            setSelectedJawabanPeriksaFakta([]);
            setSelectedJawabanSurvey([]);
        }
    }, [hidemodal, countSukses, errorMessage, successMessage]);

    return (
        <>
            <Modal
                aria-labelledby='modalPolingDetailLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                show={showModalDetail}
                onHide={hidemodal}
            >
                <div className='modal-header modalheaderlogin'>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        <h4 className='modal-titlelogin'>{polingDetailData?.judul}</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                                {errorMessage}
                            </div>
                        </div>
                        {polingDetailData.length !== 0 &&
                            polingDetailData?.jenis_polings[0]?.id === 2 &&
                            <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                                <LazyLoadImage src='/img/success.svg' alt='success' />
                            </div>
                        }
                        {polingDetailData.length !== 0 && polingDetailData?.jenis_polings[0]?.id === 1 &&
                            <>
                                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                                    {parse('<p className="submodal-titlelogin textsurvey">' + polingDetailData?.deskripsi + '</p>')}
                                </div>
                                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                                    <div className='row'>
                                        {polingDetailData?.jenis_items[0]?.id === 1 &&
                                            polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopilihan) => (
                                                <div key={nopilihan} className='col-md-6'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.pilihan[0]?.id, polingdetail?.beritas[0]?.judul)}>
                                                        <LazyLoadImage src={polingdetail?.beritas[0]?.foto} alt='votingberita' className='imgvoting' />
                                                        <p className='textvoting'>{potongText(polingdetail?.beritas[0]?.judul)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        {polingDetailData?.jenis_items[0]?.id === 2 &&
                                            polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopilihan) => (
                                                <div key={nopilihan} className='col-md-6'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.pilihan[0]?.id, polingdetail?.produk_hukums[0]?.judul)}>
                                                        <LazyLoadImage src='/img/default-produkhukum.svg' alt='votingprodukhukum' className='imgvoting' />
                                                        <p className='textvoting'>{potongText(polingdetail?.produk_hukums[0]?.judul)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        {polingDetailData?.jenis_items[0]?.id === 3 &&
                                            polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopilihan) => (
                                                <div key={nopilihan} className='col-md-6'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.pilihan[0]?.id, polingdetail?.tanya_jaksas[0]?.judul)}>
                                                        {polingdetail?.tanya_jaksas[0]?.gambar !== '' ? (
                                                            <LazyLoadImage src={polingdetail?.tanya_jaksas[0].gambar} alt='votingtanyajaksa' className='imgvoting' />
                                                        ) : (
                                                            <LazyLoadImage src='/img/default-tanyajaksa.svg' alt='votingtanyajaksa' className='imgvoting' />
                                                        )}
                                                        <p className='textvoting'>{potongText(polingdetail?.tanya_jaksas[0]?.judul)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        {polingDetailData?.jenis_items[0]?.id === 4 &&
                                            polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopilihan) => (
                                                <div key={polingdetail?.id} className='col-md-6'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.pilihan[0]?.id, polingdetail?.events[0]?.judul)}>
                                                        <LazyLoadImage src={polingDetailData?.events[0]?.gambar} alt='votingevent' className='imgvoting' />
                                                        <p className='textvoting'>{potongText(polingdetail?.events[0]?.judul)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className='col-12 center-align'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Nanti Saja</button>
                                </div>
                            </>
                        }
                        {polingDetailData.length !== 0 && polingDetailData?.jenis_polings[0]?.id === 2 &&
                            <>
                                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                                    {parse('<p className="submodal-titlelogin textsurvey">' + polingDetailData?.deskripsi + '</p>')}
                                </div>
                                <div className='flexcenter ratingstar'>
                                    <ReactStars
                                        count={5}
                                        onChange={(e) => submitRating(e, polingDetailData?.ID, polingDetailData?.poling_details[0]?.pilihan[0]?.id)}
                                        isHalf={false}
                                        emptyIcon={<LazyLoadImage src='/img/sc-poling-empty.svg' alt='star' />}
                                        filledIcon={<LazyLoadImage src='/img/sc-poling-fill.svg' alt='star' />}
                                    />
                                </div>
                                <div className='col-12 center-align'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Nanti Saja</button>
                                </div>
                            </>
                        }
                        {polingDetailData.length !== 0 && polingDetailData?.jenis_polings[0]?.id === 3 &&
                            <>
                                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                                    {parse('<p className="submodal-titlelogin">' + polingDetailData?.deskripsi + '</p>')}
                                    {polingDetailData?.jenis_items[0]?.id === 1 &&
                                        polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopertanyaan) => (
                                            <div key={nopertanyaan}>
                                                <div className='card-periksafakta'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.id, polingdetail?.beritas[0]?.judul)}>
                                                        <LazyLoadImage src={polingdetail?.beritas[0]?.foto} alt='votingberita' className='imgperiksafakta' />
                                                        <p className='textvoting'>{potongText(polingdetail?.beritas[0]?.judul)}</p>
                                                    </div>
                                                    <p className='textsurveypertanyaan mb-3 ml-2 left-align'>{polingdetail?.pertanyaan}</p>
                                                    <div className='row'>
                                                        {polingdetail?.pilihan && polingdetail?.pilihan.map((polingdetailpilihan, nopilihan) => (
                                                            <div key={nopilihan} className='col-md-4'>
                                                                <div className='form-check'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type='radio'
                                                                        name={'pertanyaan_' + nopertanyaan}
                                                                        id={'radioperiksafakta' + polingdetailpilihan?.id}
                                                                        checked={selectedJawabanPeriksaFakta[nopertanyaan]?.poling_details_id === polingdetailpilihan?.id}
                                                                        onChange={() => handleRadioPeriksaFakta(nopertanyaan, polingdetailpilihan?.id, polingdetailpilihan?.pilihan)}
                                                                    />
                                                                    <label className='form-check-label textjawabanpolingsurvey' htmlFor={'radioperiksafakta' + polingdetailpilihan?.id}>
                                                                        {polingdetailpilihan?.pilihan}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {polingDetailData?.jenis_items[0]?.id === 2 &&
                                        polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopertanyaan) => (
                                            <div key={nopertanyaan}>
                                                <div className='card-periksafakta'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.id, polingdetail?.produk_hukums[0]?.judul)}>
                                                        <LazyLoadImage src='/img/default-produkhukum.svg' alt='votingprodukhukum' className='imgperiksafakta' />
                                                        <p className='textvoting'>{potongText(polingdetail?.produk_hukums[0]?.judul)}</p>
                                                    </div>
                                                    <p className='textsurveypertanyaan mb-3 ml-2 left-align'>{polingdetail?.pertanyaan}</p>
                                                    <div className='row'>
                                                        {polingdetail?.pilihan && polingdetail?.pilihan.map((polingdetailpilihan, nopilihan) => (
                                                            <div key={nopilihan} className='col-md-4'>
                                                                <div className='form-check'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type='radio'
                                                                        name={'pertanyaan_' + nopertanyaan}
                                                                        id={'radioperiksafakta' + polingdetailpilihan?.id}
                                                                        checked={selectedJawabanPeriksaFakta[nopertanyaan]?.poling_details_id === polingdetailpilihan?.id}
                                                                        onChange={() => handleRadioPeriksaFakta(nopertanyaan, polingdetailpilihan?.id, polingdetailpilihan?.pilihan)}
                                                                    />
                                                                    <label className='form-check-label textjawabanpolingsurvey' htmlFor={'radioperiksafakta' + polingdetailpilihan?.id}>
                                                                        {polingdetailpilihan?.pilihan}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {polingDetailData?.jenis_items[0]?.id === 3 &&
                                        polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopertanyaan) => (
                                            <div key={nopertanyaan}>
                                                <div className='card-periksafakta'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.id, polingdetail?.tanya_jaksas[0]?.judul)}>
                                                        {polingdetail?.tanya_jaksas[0].gambar !== '' ? (
                                                            <LazyLoadImage src={polingdetail?.tanya_jaksas[0].gambar} alt='votingtanyajaksa' className='imgperiksafakta' />
                                                        ) : (
                                                            <LazyLoadImage src='/img/default-tanyajaksa.svg' alt='votingtanyajaksa' className='imgperiksafakta' />
                                                        )}
                                                        <p className='textvoting'>{potongText(polingdetail?.tanya_jaksas[0]?.judul)}</p>
                                                    </div>
                                                    <p className='textsurveypertanyaan mb-3 ml-2 left-align'>{polingdetail?.pertanyaan}</p>
                                                    <div className='row'>
                                                        {polingdetail?.pilihan && polingdetail?.pilihan.map((polingdetailpilihan, nopilihan) => (
                                                            <div key={nopilihan} className='col-md-4'>
                                                                <div className='form-check'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type='radio'
                                                                        name={'pertanyaan_' + nopertanyaan}
                                                                        id={'radioperiksafakta' + polingdetailpilihan?.id}
                                                                        checked={selectedJawabanPeriksaFakta[nopertanyaan]?.poling_details_id === polingdetailpilihan?.id}
                                                                        onChange={() => handleRadioPeriksaFakta(nopertanyaan, polingdetailpilihan?.id, polingdetailpilihan?.pilihan)}
                                                                    />
                                                                    <label className='form-check-label textjawabanpolingsurvey' htmlFor={'radioperiksafakta' + polingdetailpilihan?.id}>
                                                                        {polingdetailpilihan?.pilihan}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {polingDetailData?.jenis_items[0]?.id === 4 &&
                                        polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopertanyaan) => (
                                            <div key={nopertanyaan}>
                                                <div className='card-periksafakta'>
                                                    <div className='card-voting' onClick={(e) => submitVoting(e, polingDetailData?.ID, polingdetail?.id, polingdetail?.events[0]?.judul)}>
                                                        <LazyLoadImage src={polingdetail?.events[0].gambar} alt='votingevent' className='imgvoting' />
                                                        <p className='textvoting'>{potongText(polingdetail?.events[0]?.judul)}</p>
                                                    </div>
                                                    <p className='textsurveypertanyaan mb-3 ml-2 left-align'>{polingdetail?.pertanyaan}</p>
                                                    <div className='row'>
                                                        {polingdetail?.pilihan && polingdetail?.pilihan.map((polingdetailpilihan, nopilihan) => (
                                                            <div key={nopilihan} className='col-md-4'>
                                                                <div className='form-check'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type='radio'
                                                                        name={'pertanyaan_' + nopertanyaan}
                                                                        id={'radioperiksafakta' + polingdetailpilihan?.id}
                                                                        checked={selectedJawabanPeriksaFakta[nopertanyaan]?.poling_details_id === polingdetailpilihan?.id}
                                                                        onChange={() => handleRadioPeriksaFakta(nopertanyaan, polingdetailpilihan?.id, polingdetailpilihan?.pilihan)}
                                                                    />
                                                                    <label className='form-check-label textjawabanpolingsurvey' htmlFor={'radioperiksafakta' + polingdetailpilihan?.id}>
                                                                        {polingdetailpilihan?.pilihan}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Nanti Saja</button>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={(e) => submitPeriksaFakta(e, polingDetailData?.ID)}>Kirim</button>
                                </div>
                            </>
                        }
                        {polingDetailData.length !== 0 && polingDetailData?.jenis_polings[0]?.id === 4 &&
                            <>
                                <div className='col-xl-12 col-md-12 col-sm-12 center-align deskripsipolingdetail'>
                                    {parse('<p className="submodal-titlelogin textsurvey">' + polingDetailData?.deskripsi + '</p>')}
                                </div>
                                <div className='col-xl-12 col-md-12 col-sm-12 mt-3'>
                                    <div className='row'>
                                        {polingDetailData?.poling_details && polingDetailData?.poling_details.map((polingdetail, nopertanyaan) => (
                                            <div key={nopertanyaan} className='col-md-12'>
                                                <p className='textsurveypertanyaan'>{nopertanyaan + 1 + '. ' + polingdetail?.pertanyaan}</p>
                                                {polingdetail?.pilihan && polingdetail?.pilihan.map((polingdetailpilihan, nopilihan) => (
                                                    <div key={nopilihan} className='form-check mt-3 ml-3'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            name={'pertanyaan_' + nopertanyaan}
                                                            id={'radiosurvey' + polingdetailpilihan?.id}
                                                            checked={selectedJawabanSurvey[nopertanyaan]?.poling_details_id === polingdetailpilihan?.id}
                                                            onChange={() => handleRadioSurvey(nopertanyaan, polingdetailpilihan?.id, polingdetailpilihan?.pilihan)}
                                                        />
                                                        <label className='form-check-label textjawabanpolingsurvey' htmlFor={'radiosurvey' + polingdetailpilihan?.id}>
                                                            {polingdetailpilihan?.pilihan}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Nanti Saja</button>
                                </div>
                                <div className='col-6'>
                                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={(e) => submitSurvey(e, polingDetailData?.ID)}>Kirim</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Modal>
            <ModalSuksesComponent
                showmodal={showModalSukses}
                hidemodal={hideModalSukses}
                textsukses={textSukses}
            />
        </>
    )
}

export default ModalPolingDetail