import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useRegister = (data) => {
    const [errorRegister, setErrorRegister] = useState('');
    const [successRegister, setSuccessRegister] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorRegister('');
                setSuccessRegister('');
                const API_URL = 'auth/register/member';
                const fetchData = async () => {
                    await useAxios.post(API_URL,
                        JSON.stringify({
                            jenis_kelamins_id: data?.jenis_kelamins_id,
                            nama: data?.nama,
                            no_ektp: data?.no_ektp,
                            email: data?.email,
                            no_telepon: data?.no_telepon,
                            username: data?.username,
                            password: data?.password,
                            tanggal_lahir: data?.tanggal_lahir,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        localStorage.setItem('user', JSON.stringify(res?.data));
                        setSuccessRegister(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorRegister('API register tidak ada response dari server');
                        } else {
                            setErrorRegister(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorRegister, successRegister];
}

export default useRegister;