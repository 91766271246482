import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";

const useProfil = (data) => {
    const [profilData, setProfilData] = useState([]);
    const [errorProfil, setErrorProfil] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorProfil('');
                const API_URL = 'profil';
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        if (data?.tipe === 'admin') {
                            localStorage.setItem('admin', JSON.stringify(res?.data));
                        } else if (data?.tipe === 'user') {
                            localStorage.setItem('user', JSON.stringify(res?.data));
                        }

                        setProfilData(res?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorProfil('API profil tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            if(data?.tipe === 'admin') {
                                localStorage.removeItem('admin');
                                window.location.href = '/admin/login';
                            } else {
                                localStorage.removeItem('user');
                                window.location.href = '/';
                            }
                        } else {
                            setErrorProfil(err.response.data.data);
                        }
                    });
                };
                fetchData();
            }
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [profilData, errorProfil];
}

export default useProfil;