import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useLoginGoogle = (data) => {
    const [errorLoginGoogle, setErrorLoginGoogle] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorLoginGoogle('');
                const API_URL = 'https://www.googleapis.com/oauth2/v1/userinfo?access_token='+data;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        const API_URL = 'auth/login/sso';
                        useAxios.post(API_URL,
                            JSON.stringify({
                                foto: res?.data?.picture,
                                nama: res?.data?.name,
                                email: res?.data?.email,
                                google_id: res?.data?.id,
                            }),
                            {
                                headers: { 'Content-Type': 'application/json' },
                            }
                        ).then(res => {
                            localStorage.setItem('user', JSON.stringify(res?.data));
                            window.location.href = '/dashboard/member/profil';
                        }).catch(err => {
                            if (!err.res) {
                                setErrorLoginGoogle('API login google tidak ada response dari server');
                            } else {
                                setErrorLoginGoogle(err.res);
                            }
                        });
                    }).catch(err => {
                        if (!err.res) {
                            setErrorLoginGoogle('API google oauth tidak ada response dari server');
                        } else {
                            setErrorLoginGoogle(err.res);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorLoginGoogle];
}

export default useLoginGoogle;