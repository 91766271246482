import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useLogout = (data) => {
    const [errorLogout, setErrorLogout] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorLogout('');
                const API_URL = 'auth/logout';
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        localStorage.setItem('admin', JSON.stringify(res.data));
                        window.location.href = '/dashboard/admin';
                    }).catch(err => {
                        if (!err.response) {
                            setErrorLogout('API logout tidak ada response dari server');
                        } else {
                            setErrorLogout(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorLogout];
}

export default useLogout;