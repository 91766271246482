import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useKotaKabupaten = (data) => {
    const [kotaKabupatenData, setKotaKabupatenData] = useState([]);
    const [totalKotaKabupatenData, setTotalKotaKabupatenData] = useState(0);
    const [loadingKotaKabupaten, setLoadingKotaKabupaten] = useState(true);
    const [errorKotaKabupaten, setErrorKotaKabupaten] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorKotaKabupaten('');
                const API_URL = 'kotakabupaten?offset=' + data?.offset + '&limit=' + data?.limit + '&cari=' + data?.cari;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        setKotaKabupatenData(res.data.data);
                        setTotalKotaKabupatenData(res.data.total_data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorKotaKabupaten('API Kota Kabupaten tidak ada response dari server');
                        } else {
                            setErrorKotaKabupaten(err.response.data.data);
                        }
                    });
                };
                setLoadingKotaKabupaten(false);
                fetchData();
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [data]);

    return [kotaKabupatenData, totalKotaKabupatenData, loadingKotaKabupaten, errorKotaKabupaten];
}

export default useKotaKabupaten;