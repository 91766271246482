import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useLoginFacebook = (data) => {
    const [errorLoginFacebook, setErrorLoginFacebook] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorLoginFacebook('');
                const API_URL = 'https://graph.facebook.com/v18.0/me?access_token='+data+'&fields=id%2Cfirst_name%2Clast_name%2Cmiddle_name%2Cname%2Cname_format%2Cpicture%2Cshort_name%2Cemail&locale=en_EN&method=get&pretty=0&sdk=joey&suppress_http_code=1';
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        const API_URL = 'auth/login/sso';
                        useAxios.post(API_URL,
                            JSON.stringify({
                                foto: "https://graph.facebook.com/"+res?.data?.id+"/picture?type=large&redirect=true",
                                nama: res?.data?.name,
                                email: res?.data?.email,
                                facebook_id: res?.data?.id,
                            }),
                            {
                                headers: { 'Content-Type': 'application/json' },
                            }
                        ).then(res => {
                            localStorage.setItem('user', JSON.stringify(res?.data));
                            window.location.href = '/dashboard/member/profil';
                        }).catch(err => {
                            if (!err.res) {
                                setErrorLoginFacebook('API login facebook tidak ada response dari server');
                            } else {
                                setErrorLoginFacebook(err.res);
                            }
                        });
                    }).catch(err => {
                        if (!err.res) {
                            setErrorLoginFacebook('API facebook oauth tidak ada response dari server');
                        } else {
                            setErrorLoginFacebook(err.res);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorLoginFacebook];
}

export default useLoginFacebook;