import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useTanyaJaksaTambah = (data) => {
    const [idBerita, setIdBerita] = useState(0);
    const [errorTanyaJaksaTambah, setErrorTanyaJaksaTambah] = useState('');
    const [successTanyaJaksaTambah, setSuccessTanyaJaksaTambah] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorTanyaJaksaTambah('');
                setSuccessTanyaJaksaTambah('');
                const API_URL = 'tanyajaksa/tambah';
                const fetchData = async () => {
                    await useAxios.post(API_URL,
                        JSON.stringify({
                            instansis_id: data?.instansis_id,
                            kota_kabupatens_id: data?.kota_kabupatens_id,
                            kategori_laporan_subs_id: data?.kategori_laporan_subs_id,
                            user_tanggapans_id: data?.user_tanggapans_id,
                            judul: data?.judul,
                            konten: data?.konten,
                            tanggapan: data?.tanggapan,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setIdBerita(res?.data?.data?.ID);
                        setSuccessTanyaJaksaTambah(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorTanyaJaksaTambah('API tambah tanya jaksa tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('user');
                            window.location.href = '/';
                        } else {
                            setErrorTanyaJaksaTambah(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [idBerita, errorTanyaJaksaTambah, successTanyaJaksaTambah];
}

export default useTanyaJaksaTambah;