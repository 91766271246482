import { React, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useMasyarakatUploadFotoEktp from '../../../api/useMasyarakatUploadFotoEktp';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalUploadEktp = ({ showmodal, hidemodal }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [previewFotoEktp, setPreviewFotoEktp] = useState('/img/uploadktp.svg');
    const [fotoEktp, setFotoEktp] = useState(null);
    const [tipeFotoEktp, setTipeFotoEktp] = useState('');
    const [ukuranFotoEktp, setUkuranFotoEktp] = useState('');
    const [errorFotoEktp, setErrorFotoEktp] = useState('');
    const [postFotoKtp, setPostFotoKtp] = useState([]);
    const [errorUploadFotoEktp, successUploadFotoEktp] = useMasyarakatUploadFotoEktp(postFotoKtp);
    const [countSukses, setCountSukses] = useState(0);
    const [showModalUploadEktp, setShowModalUploadEktp] = useState(false);

    useEffect(() => {
        setShowModalUploadEktp(showmodal);
        if (showmodal === true) {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
        }
    }, [showmodal]);

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('user'));
        if (auth?.data?.foto_ektp) {
            setPreviewFotoEktp(auth?.data?.foto_ektp);
        } else {
            setPreviewFotoEktp('/img/uploadktp.svg');
        }
    }, []);

    const handleUploadFoto = (e) => {
        if (e.target.files[0] !== undefined) {
            setPreviewFotoEktp(URL.createObjectURL(e.target.files[0]));
            setFotoEktp(e.target.files[0]);
            setTipeFotoEktp(e.target.files[0].type);
            setUkuranFotoEktp(e.target.files[0].size);
        }
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        setCountSukses(0);
        setErrorMessage('');
        setSuccessMessage('');
        const auth = JSON.parse(localStorage.getItem('user'));
        if (fotoEktp === null) {
            setErrorFotoEktp('Foto belum diisi.');
        } else {
            const usersdata = {
                foto: fotoEktp,
                tipefoto: tipeFotoEktp,
                ukuranfoto: ukuranFotoEktp,
                token: auth?.data?.token,
            }
            setPostFotoKtp(usersdata);
        }
    }

    useEffect(() => {
        if (errorUploadFotoEktp !== '') {
            setErrorMessage(errorUploadFotoEktp);
            setCountSukses(0);
        }
    }, [errorUploadFotoEktp]);

    useEffect(() => {
        if (successUploadFotoEktp !== '') {
            setSuccessMessage(successUploadFotoEktp);
            setCountSukses(1);
        }
    }, [successUploadFotoEktp]);

    useEffect(() => {
        if (countSukses === 1 && errorMessage === '' && successMessage !== '') {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            hidemodal();
        }
    }, [hidemodal, countSukses, errorMessage, successMessage]);

    return (
        <Modal
            aria-labelledby='modalUploadEktpLabel'
            data-backdrop='static'
            data-keyboard='false'
            role='dialog'
            tabIndex='-1'
            centered
            show={showModalUploadEktp}
            onHide={hidemodal}
        >
            <div className='modal-header modalheaderlogin'>
                <div className='row center-align'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titlelogin'>Unggah KTP</h4>
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h5 className='submodal-titlelogin'>Pilih foto yang jelas dan tidak kabur, serta tulisan dapat dibaca dengan mudah.</h5>
                    </div>
                </div>
            </div>
            <div className='modal-body'>
                <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                    {errorMessage}
                </div>
                <div className='form-group center-align'>
                    <label className={errorFotoEktp ? 'labeluploadcustom is-errorform' : 'labeluploadcustom'} htmlFor='foto_ektp'>
                        <input
                            id='foto_ektp'
                            type='file'
                            className='uploadfilecustom'
                            onChange={handleUploadFoto}
                            accept='.png, .jpeg, .jpg, .gif, .svg'
                        />
                        <LazyLoadImage className='previewimageupload' src={previewFotoEktp} alt='upload' />
                    </label>
                    <div className='errorform mb-2 left-align'>{errorFotoEktp}</div>
                </div>
                <button className='btn btn-lg btn-light btndaftarlogin' type='button' disabled={!fotoEktp} onClick={handlesubmit}>Lanjutkan</button>
            </div>
        </Modal>
    )
}

export default ModalUploadEktp