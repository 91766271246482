import { React, useEffect, useState } from 'react';
import ModalLoginComponent from './modal/login/ModalLogin';
import ModalLaporComponent from './modal/lapor/ModalLapor';
import { konversiSlug, konversiSlugKeString, waktuBerlalu } from '../utils/Utils';
import ModalEditKataSandiComponent from './modal/katasandi/ModalEditKataSandi';
import ModalKeluarComponent from './modal/keluar/ModalKeluar';
import ModalKeluarAdminComponent from './modal/keluar/ModalKeluarAdmin';
import ModalPolingComponent from './modal/poling/ModalPoling';
import ModalVerifikasiAkunComponent from './modal/verifikasi/ModalVerifikasiAkun';
import usePoling from '../api/usePoling';
import usePengaturanUmum from '../api/usePengaturanUmum';
import useProfil from '../api/useProfil';
import useNotifikasi from '../api/useNotifikasi';
import useNotifikasiStatus from '../api/useNotifikasiStatus';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Layout = ({ children }) => {
    const tahunsekarang = new Date().getFullYear();
    const [pengaturanUmumData] = usePengaturanUmum();

    const [profildata, setProfilData] = useState('');
    const [auth] = useProfil(profildata);
    const authadmin = JSON.parse(localStorage.getItem('admin'));

    const url = window.location.pathname;
    const segment1 = url.split('/')[1];
    const segment2 = url.split('/')[2];
    const segment3 = url.split('/')[3];
    const segment4 = url.split('/')[4];

    const [getPolingData, setPolingData] = useState([]);
    const [, totalPolingData, ,] = usePoling(getPolingData);
    const [showModalPoling, setShowModalPoling] = useState(false);

    const [listnotifikasimasyarakatdata, setlistnotifikasimasyarakatdata] = useState([]);
    const [listNotifikasiMasyarakatData, , ,] = useNotifikasi(listnotifikasimasyarakatdata);

    const [listnotifikasiadmindata, setlistnotifikasiadmindata] = useState([]);
    const [listNotifikasiAdminData, , ,] = useNotifikasi(listnotifikasiadmindata);

    const [updatestatusnotifikasi, setupdatestatusnotifikasi] = useState([]);
    const [,] = useNotifikasiStatus(updatestatusnotifikasi);

    const [pencarianAdmin, setPencarianAdmin] = useState('');
    const [pencarian, setPencarian] = useState('');
    const [showIconPoling, setShowIconPoling] = useState(true);

    useEffect(() => {
        if (segment2 === 'admin' && segment1 !== 'lupa-katasandi' && segment1 !== 'hapus-akun' && segment1 !== 'verifikasi-kode' && segment1 !== 'reset-katasandi' && segment2 !== 'login') {
            const authadmin = JSON.parse(localStorage.getItem('admin'));
            if (authadmin !== null) {
                const notifikasiadmindata = {
                    token: authadmin?.data?.token,
                    offset: 0,
                    limit: 0,
                    cari: '',
                    tipe: 'admin',
                }
                setlistnotifikasiadmindata(notifikasiadmindata);
            }
        } else {
            const getauth = JSON.parse(localStorage.getItem('user'));
            const polingData = {
                token: getauth?.data?.token,
                offset: 0,
                limit: 0,
                cari: '',
            }
            setPolingData(polingData);

            if (getauth !== null) {
                const userdata = {
                    token: getauth?.data?.token,
                    tipe: 'user',
                }
                setProfilData(userdata);
            }
        }

        if (segment1 === 'pencarian' && segment2 !== undefined) {
            setPencarian(konversiSlugKeString(segment2));
        }
        if (segment3 === 'pencarian' && segment4 !== undefined) {
            setPencarianAdmin(konversiSlugKeString(segment4));
        }
    }, [segment1, segment2, segment3, segment4]);

    useEffect(() => {
        if (segment2 !== 'admin' && segment1 !== 'lupa-katasandi' && segment1 !== 'hapus-akun' && segment1 !== 'verifikasi-kode' && segment1 !== 'reset-katasandi' && segment2 !== 'login') {
            if (auth.length !== 0) {
                const notifikasimasyarakatdata = {
                    token: auth?.data?.token,
                    offset: 0,
                    limit: 0,
                    cari: '',
                    tipe: 'masyarakat',
                }
                setlistnotifikasimasyarakatdata(notifikasimasyarakatdata);
            }
        }
    }, [auth, segment1, segment2]);

    const clickModalPoling = (e) => {
        e.preventDefault();
        setShowModalPoling(true);
    }

    const hideModalPoling = () => {
        setShowModalPoling(false);
        const getauth = JSON.parse(localStorage.getItem('user'));
        const polingData = {
            token: getauth?.data?.token,
            offset: 0,
            limit: 0,
            cari: '',
        }
        setPolingData(polingData);
    }

    function closeIconPoling() {
        setShowIconPoling(false);
    }

    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalLapor, setShowModalLapor] = useState(false);
    const [showModalVerifikasiAkun, setShowModalVerifikasiAkun] = useState(false);

    const clickModalLogin = (e) => {
        e.preventDefault();
        setShowModalLogin(true);
    }

    const hideModalLogin = () => {
        setShowModalLogin(false);
    }

    const clickModalVerifikasiAkun = (e) => {
        e.preventDefault();
        setShowModalVerifikasiAkun(true);
    }

    const hideModalVerifikasiAkun = () => {
        window.location.href = '/dashboard/member/profil';
    }

    const onHideModalVerifikasiAkun = () => {
        setShowModalVerifikasiAkun(false);
    }

    const [urlHeader, setUrlHeader] = useState('');
    const [judulHeader, setJudulHeader] = useState('');
    const [showModalKeluarAdmin, setShowModalKeluarAdmin] = useState(false);

    const clickModalKeluarAdmin = (e) => {
        e.preventDefault();
        // setShowModalKeluarAdmin(true);
        window.location.href = process.env.REACT_APP_SSO_BASE_URL + "/realms/" + process.env.REACT_APP_SSO_REALM + "/protocol/openid-connect/logout?client_id=" + process.env.REACT_APP_SSO_CLIENT_ID + "&post_logout_redirect_uri=" + encodeURIComponent(process.env.REACT_APP_SSO_REDIRECT_URI_LOGOUT)
    }

    const hideModalKeluarAdmin = () => {
        setShowModalKeluarAdmin(false);
    }

    useEffect(() => {
        if (segment3 === '') {
            setJudulHeader('Dashboard');
            setUrlHeader('/dashboard/admin');
        }
        else if (segment3 === undefined) {
            setJudulHeader('Dashboard');
            setUrlHeader('/dashboard/admin');
        }
        else if (segment3 === 'berita') {
            setJudulHeader('Berita & Informasi');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'produk-hukum') {
            setJudulHeader('Berita & Informasi');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'kategori-berita') {
            setJudulHeader('Daftar Kategori');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'kategori-produk-hukum') {
            setJudulHeader('Daftar Kategori');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'hierarki-peraturan') {
            setJudulHeader('Daftar Kategori');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'poling') {
            setJudulHeader('Poling');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'event') {
            setJudulHeader('Event');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'tanya-jaksa') {
            setJudulHeader('Tanya Jaksa');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'pengaduan-masyarakat') {
            setJudulHeader('Pengaduan Masyarakat');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'helpdesk') {
            setJudulHeader('Helpdesk');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'faq') {
            setJudulHeader('Helpdesk');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'kritik-saran') {
            setJudulHeader('Kritik & Saran');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'laporan') {
            setJudulHeader('Laporan');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'pengaturan') {
            setJudulHeader('Pengaturan');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'instansi') {
            setJudulHeader('Pengaturan Lapor');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
        else if (segment3 === 'kategori-laporan') {
            setJudulHeader('Pengaturan Lapor');
            setUrlHeader('/dashboard/admin/' + segment3);
        }
    }, [segment3]);

    const [showModalEditKataSandi, setShowModalEditKataSandi] = useState(false);
    const [showModalKeluar, setShowModalKeluar] = useState(false);

    const clickModalEditKataSandi = (e) => {
        e.preventDefault();
        setShowModalEditKataSandi(true);
    }

    const hideModalEditKataSandi = () => {
        setShowModalEditKataSandi(false);
    }

    const clickModalLapor = (e) => {
        e.preventDefault();
        setShowModalLapor(true);
    }

    const hideModalLapor = () => {
        setShowModalLapor(false);
    }

    const handlePencarianAdmin = (e) => {
        if (e.key === 'Enter') {
            if (pencarianAdmin !== '') {
                window.location.href = '/dashboard/admin/pencarian/' + konversiSlug(pencarianAdmin);
            }
        }
    }

    const handlePencarian = (e) => {
        if (e.key === 'Enter') {
            if (pencarian !== '') {
                window.location.href = '/pencarian/' + konversiSlug(pencarian);
            }
        }
    }

    const clickModalKeluar = (e) => {
        e.preventDefault();
        setShowModalKeluar(true);
    }

    const hideModalKeluar = () => {
        setShowModalKeluar(false);
    }

    const handleUpdateStatusNotifikasi = (e) => {
        e.preventDefault();
        const updatestatusdata = {
            token: auth?.data?.token,
        }
        setupdatestatusnotifikasi(updatestatusdata);

        const notifikasimasyarakatdata = {
            token: auth?.data?.token,
            offset: 0,
            limit: 5,
            cari: '',
            tipe: 'masyarakat',
        }
        setlistnotifikasimasyarakatdata(notifikasimasyarakatdata);
    }

    const handleUpdateStatusNotifikasiAdmin = (e) => {
        e.preventDefault();
        const updatestatusdata = {
            token: authadmin?.data?.token,
        }
        setupdatestatusnotifikasi(updatestatusdata);

        const notifikasiadmindata = {
            token: authadmin?.data?.token,
            offset: 0,
            limit: 5,
            cari: '',
            tipe: 'admin',
        }
        setlistnotifikasiadmindata(notifikasiadmindata);
    }

    function openSidebarAdmin() {
        const sidebar = document.getElementById("sidebaradmin");
        sidebar.classList.add("c-sidebar-show");
        const parent = document.querySelector("body");
        const newDiv = document.createElement("div");
        newDiv.setAttribute('class', 'c-sidebar-backdrop c-fade c-show');
        parent.appendChild(newDiv);
    }

    function closeSidebarAdmin() {
        var sidebarbackdrop = document.getElementsByClassName("c-sidebar-backdrop");
        sidebarbackdrop[0].remove()
        var element = document.getElementById("sidebaradmin");
        element.classList.remove("c-sidebar-show");
    }

    function openSidebar() {
        const sidebar = document.getElementById("sidebar");
        sidebar.classList.add("c-sidebar-show");
        const parent = document.querySelector("body");
        const newDiv = document.createElement("div");
        newDiv.setAttribute('class', 'c-sidebar-backdrop c-fade c-show');
        parent.appendChild(newDiv);
    }

    function closeSidebar() {
        var sidebarbackdrop = document.getElementsByClassName("c-sidebar-backdrop");
        sidebarbackdrop[0].remove()
        var element = document.getElementById("sidebar");
        element.classList.remove("c-sidebar-show");
    }

    function toogleMenuAdmin() {
        var element = document.getElementById("tooglesidebaradmin");
        var showtoogleadmin = document.getElementsByClassName("nav-group c-show");
        if (showtoogleadmin.length !== 0) {
            element.classList.remove("c-show");
        } else {
            element.classList.add("c-show");
        }
    }

    if (segment1 === 'dashboard') {
        if (segment2 === 'admin') {
            return (
                <>
                    <div className={'c-sidebar c-sidebar-fixed c-sidebar-lg-show ' + (pengaturanUmumData && pengaturanUmumData?.tema === 'temaram' ? 'c-sidebar-dark' : 'c-sidebar-light')} id='sidebaradmin'>
                        <div className='sidebar-header'>
                            <button className='btncloseheader btn-close' type='button' data-coreui-dismiss='offcanvas' aria-label='Close' onClick={closeSidebarAdmin}></button>
                        </div>
                        <div className='c-sidebar-brand c-sidebar-admin d-md-down-none'>
                            <a href='/' target='_blank'>
                                {pengaturanUmumData?.tema === 'temaram' ? (
                                    <>
                                        <LazyLoadImage className='c-sidebar-brand-full' src={pengaturanUmumData?.logo_gelap} width='210' alt='Logo' />
                                        <LazyLoadImage className='c-sidebar-brand-minimized' src={pengaturanUmumData?.logo_gelap} width='50' alt='Logo' />
                                    </>
                                ) : (
                                    <>
                                        <LazyLoadImage className='c-sidebar-brand-full' src={pengaturanUmumData?.logo_terang} width='210' alt='Logo' />
                                        <LazyLoadImage className='c-sidebar-brand-minimized' src={pengaturanUmumData?.logo_terang} width='50' alt='Logo' />
                                    </>
                                )}
                            </a>
                        </div>
                        <ul className='c-sidebar-nav c-sidebar-nav-member' style={{ 'marginBottom': '25px' }}>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin' ? 'c-active' : '')} href='/dashboard/admin'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-dachboard.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Dashboard</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Informasi aktifitas terkini</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/berita' || window.location.pathname === '/dashboard/admin/produk-hukum' || window.location.pathname === '/dashboard/admin/kategori-berita' || window.location.pathname === '/dashboard/admin/kategori-produk-hukum' || window.location.pathname === '/dashboard/admin/hierarki-peraturan' ? 'c-active' : '')} href='/dashboard/admin/berita'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-news.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Berita & Informasi</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Kumpulan konten berita dan informasi</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/poling' ? 'c-active' : '')} href='/dashboard/admin/poling'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-poling.svg' alt='poling' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Poling</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Lakukan survey untuk masyarakat</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/event' ? 'c-active' : '')} href='/dashboard/admin/event'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-event.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Event</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Buat rencana besar untuk masyarakat</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/statistik' ? 'c-active' : '')} href='/dashboard/admin/statistik'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/bx-pie-chart-alt-2.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Statistik</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Kumpulan data statistik seputar AdhyaksaPedia</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/tanya-jaksa' || window.location.pathname === '/dashboard/admin/kategori-tanya-jaksa' ? 'c-active' : '')} href='/dashboard/admin/tanya-jaksa'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/bx-help-circle.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Tanya Jaksa</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Berikan informasi untuk pengguna</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/pengaduan-masyarakat' ? 'c-active' : '')} href='/dashboard/admin/pengaduan-masyarakat'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-pengaduan.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Pengaduan Masyarakat</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Apa kata masyarakat</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/helpdesk' || window.location.pathname === '/dashboard/admin/faq' || window.location.pathname === '/dashboard/admin/kategori-faq' ? 'c-active' : '')} href='/dashboard/admin/helpdesk'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-helpdesk.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Helpdesk</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Some awesome here!</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/kritik-saran' ? 'c-active' : '')} href='/dashboard/admin/kritik-saran'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-critics.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Kritik & Saran</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Aspirasi dari masyarakat</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item c-sidebar-admin'>
                                <a className={'c-sidebar-nav-link c-row nav-link-admin ' + (window.location.pathname === '/dashboard/admin/laporan' ? 'c-active' : '')} href='/dashboard/admin/laporan'>
                                    <div className='col-sm-2'>
                                        <LazyLoadImage className='iconsidebaradmin' src='/img/sc-report.svg' alt='dashboard' />
                                    </div>
                                    <div className='col-sm-10 col-custom-sidebar'>
                                        <div className='col-sm-12'>
                                            <p className='textsidebar'>Laporan</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='textsubsidebar'>Akses berbagai informasi big data</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            {authadmin?.data?.tipe_users_id === 1 && (
                                <li id="tooglesidebaradmin" className={'c-sidebar-nav-dropdown nav-group c-sidebar-nav-item c-sidebar-admin c-show ' + (window.location.pathname === '/dashboard/admin/instansi' || window.location.pathname === '/dashboard/admin/kategori-laporan' || window.location.pathname === '/dashboard/admin/pengaturan/anggota' || window.location.pathname === '/dashboard/admin/pengaturan/masyarakat' || window.location.pathname === '/dashboard/admin/pengaturan/pengaturan-umum' || window.location.pathname === '/dashboard/admin/pengaturan/log-aktivitas/anggota' || window.location.pathname === '/dashboard/admin/pengaturan/log-aktivitas/masyarakat' ? 'c-show' : '')}>
                                    <button type='button' className='btnnostyle btntextleft c-sidebar-nav-dropdown-toggle c-row nav-link-admin c-dropdown-toggle-custom' onClick={toogleMenuAdmin}>
                                        <div className='col-sm-2'>
                                            <LazyLoadImage className='iconsidebaradmin' src='/img/sc-setting.svg' alt='dashboard' />
                                        </div>
                                        <div className='col-sm-10 col-custom-sidebar'>
                                            <div className='col-sm-12'>
                                                <p className='textsidebar'>Pengaturan</p>
                                            </div>
                                            <div className='col-sm-12'>
                                                <p className='textsubsidebar'>Fungsi aplikasi dan pengguna</p>
                                            </div>
                                        </div>
                                    </button>
                                    <ul className='c-sidebar-nav-dropdown-items c-sidebar-sub-background-white c-sidebar-admin'>
                                        <li className='c-sidebar-nav-item c-sidebar-nav-item-admin-font'>
                                            <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/admin/instansi' || window.location.pathname === '/dashboard/admin/kategori-laporan' ? 'c-active' : '')} href='/dashboard/admin/instansi'>
                                                Lapor
                                            </a>
                                        </li>
                                        <li className='c-sidebar-nav-item c-sidebar-nav-item-admin-font'>
                                            <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/admin/pengaturan/anggota' || window.location.pathname === '/dashboard/admin/pengaturan/masyarakat' ? 'c-active' : '')} href='/dashboard/admin/pengaturan/anggota'>
                                                Pengguna
                                            </a>
                                        </li>
                                        <li className='c-sidebar-nav-item c-sidebar-nav-item-admin-font'>
                                            <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/admin/pengaturan/pengaturan-umum' ? 'c-active' : '')} href='/dashboard/admin/pengaturan/pengaturan-umum'>
                                                Umum
                                            </a>
                                        </li>
                                        <li className='c-sidebar-nav-item c-sidebar-nav-item-admin-font'>
                                            <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/admin/pengaturan/log-aktivitas/anggota' || window.location.pathname === '/dashboard/admin/pengaturan/log-aktivitas/masyarakat' ? 'c-active' : '')} href='/dashboard/admin/pengaturan/log-aktivitas/anggota'>
                                                Log Aktivitas
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                        <div className='footersidebar'>
                            <p className='texthukumloginfooteradmin'>Adhyaksa<b className='textpediafooteradmin'>Pedia</b> Admin Dashboard</p>
                            <p className='copyrightloginfooteradmin'>© {tahunsekarang} All Rights Reserved</p>
                        </div>
                    </div>
                    <div className='c-wrapper'>
                        <header className={'c-header ' + (pengaturanUmumData.tema === 'temaram' ? 'c-header-dark' : 'c-header-light') + ' c-header-dashboard-admin'}>
                            <button className='c-header-toggler c-class-toggler d-lg-none mfe-auto' type='button' onClick={openSidebarAdmin}>
                                <svg className='c-icon c-icon-lg'>
                                    <use xlinkHref='/vendors/@coreui/icons/svg/free.svg#cil-menu'></use>
                                </svg>
                            </button>
                            <a href={urlHeader} className='anostyle textdashboard'>{judulHeader}</a>
                            <ul className='c-header-nav ml-auto mx-auto'></ul>
                            <ul className='c-header-nav d-md-down-none ulimagepencarian'>
                                <li style={{ 'paddingLeft': '50px' }}>
                                    <div className='input-group divpencarian'>
                                        <input
                                            className='form-control'
                                            id='formpencarianadmin'
                                            type='text'
                                            name='formpencarian'
                                            placeholder='Cari Sesuatu...'
                                            onKeyDown={handlePencarianAdmin}
                                            onChange={(e) => setPencarianAdmin(e.target.value)}
                                            value={pencarianAdmin}
                                        />
                                        <div className='input-group-append'>
                                            <span className='input-group-text spansearching'>
                                                <i className='cil-search'></i>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className='c-header-nav ullogin mleftauto'>
                                <li className='c-header-nav-item dropdown d-md-down-none mx-2'>
                                    <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                        <LazyLoadImage src='/img/notificationbadge.svg' className='imagenotif' alt='notifikasi' />
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0 wrappernotification dropdownnotifadmin'>
                                        <div className='dropdown-header'>
                                            <p className='texttitlenotification'>Notifikasi</p>
                                        </div>
                                        <div className='breaknotif'></div>
                                        <div className='dropdown-header'>
                                            <p className='titlewaktunotifikasi'>Hari ini</p>
                                        </div>
                                        {listNotifikasiAdminData && listNotifikasiAdminData.map((notifikasiadmin, no) => (
                                            <div key={no} >
                                                {notifikasiadmin?.hariini.length !== 0 ? (
                                                    notifikasiadmin?.hariini.map((notifikasiadminhariini) => (
                                                        <div key={notifikasiadminhariini?.id} >
                                                            {notifikasiadminhariini?.status_baca === false ? (
                                                                <div className='dropdown-item new mb-1'>
                                                                    <a className='anostyle' href={notifikasiadminhariini?.url}>
                                                                        <div className='boxtextnotifikasi'>
                                                                            <p className='newnotifikasi'>Baru</p>
                                                                            <p className='textnotifikasi'>{notifikasiadminhariini?.deskripsi}</p>
                                                                            <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminhariini?.created_at)}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <div className='dropdown-item mb-1'>
                                                                    <a className='anostyle' href={notifikasiadminhariini?.url}>
                                                                        <div className='boxtextnotifikasi'>
                                                                            <p className='textnotifikasi'>{notifikasiadminhariini?.deskripsi}</p>
                                                                            <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminhariini?.created_at)}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))) : (
                                                    <div className='dropdown-item mb-1'>
                                                        <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className='dropdown-header'>
                                            <p className='titlewaktunotifikasi'>Kemarin</p>
                                        </div>
                                        {listNotifikasiAdminData && listNotifikasiAdminData.map((notifikasiadmin, no) => (
                                            <div key={no} >
                                                {notifikasiadmin?.kemarin.length !== 0 ? (
                                                    notifikasiadmin?.kemarin.map((notifikasiadminkemarin) => (
                                                        <div key={notifikasiadminkemarin?.id} >
                                                            {notifikasiadminkemarin?.status_baca === false ? (
                                                                <div className='dropdown-item new mb-1'>
                                                                    <a className='anostyle' href={notifikasiadminkemarin?.url}>
                                                                        <div className='boxtextnotifikasi'>
                                                                            <p className='newnotifikasi'>Baru</p>
                                                                            <p className='textnotifikasi'>{notifikasiadminkemarin?.deskripsi}</p>
                                                                            <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminkemarin?.created_at)}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <div className='dropdown-item mb-1'>
                                                                    <a className='anostyle' href={notifikasiadminkemarin?.url}>
                                                                        <div className='boxtextnotifikasi'>
                                                                            <p className='textnotifikasi'>{notifikasiadminkemarin?.deskripsi}</p>
                                                                            <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminkemarin?.created_at)}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))) : (
                                                    <div className='dropdown-item mb-1'>
                                                        <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className='dropdown-item border-top' style={{ 'backgroundColor': '#fff' }}>
                                            <div className='col-sm-4 left-align'>
                                                <a href='/dashboard/admin/notifikasi' className='textfooternotif'>Lihat Semua</a>
                                            </div>
                                            <div className='col-sm-8 right-align'>
                                                <button type="button" className='btnnostyle textfooternotif' onClick={handleUpdateStatusNotifikasiAdmin}>Tandai sudah dibaca semua</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='c-header-nav-item dropdown mr-3' style={{ 'marginRight': '0px', 'marginTop': '10px' }}>
                                    <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                        <div className='displayflex right-align' style={{ 'width': '100%' }}>
                                            <div className='c-row spaceprofiladmin'>
                                                <div className='col-md-12'>
                                                    <p className='greetingheaderadmin'>Welcome!</p>
                                                </div>
                                                <div className='col-md-12'>
                                                    <p className='namaheaderadmin'>{authadmin?.data?.nama}</p>
                                                </div>
                                            </div>
                                            <div className='c-avatar'>
                                                {authadmin?.data?.foto !== null && authadmin?.data?.foto !== "" ? (
                                                    <LazyLoadImage className='c-avatar-img' src={authadmin?.data?.foto} style={{ 'width': '38px', 'height': '38px', 'marginLeft': '10px' }} alt='AdhyaksaPedia' />
                                                ) : (
                                                    <LazyLoadImage className='c-avatar-img' src='/assets/img/avatars/default.png' style={{ 'width': '38px', 'height': '38px', 'marginLeft': '10px' }} alt='AdhyaksaPedia' />
                                                )}
                                            </div>
                                        </div>
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-right dropdownprofiladmin'>
                                        <a className='dropdown-item customhref' href='/dashboard/admin/profil'>
                                            <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                            &nbsp; Profil
                                        </a>
                                        <div className='dropdown-divider dropdown-divider-custom'></div>
                                        <a className='dropdown-item customhref' href='#keluar' onClick={(e) => clickModalKeluarAdmin(e)}>
                                            <LazyLoadImage src='/img/sc-log-in-circle.svg' alt='logout' />
                                            &nbsp; Keluar
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </header >
                        <div className={'c-body ' + (pengaturanUmumData.tema === 'temaram' ? 'c-body-admin-dark' : 'c-body-admin-light')}>
                            <main className='c-main c-main-admin'>
                                <div className='container-admin'>
                                    <div className='fade-in'>
                                        <div className='row'>
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    <ModalKeluarAdminComponent
                        showmodal={showModalKeluarAdmin}
                        hidemodal={hideModalKeluarAdmin}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div className='c-wrapper'>
                        <header className='c-header c-header-fixed'>
                            <button className='c-header-toggler c-class-toggler d-lg-none mfe-auto' type='button' onClick={openSidebar}>
                                <svg className='c-icon c-icon-lg'>
                                    <use xlinkHref='/vendors/@coreui/icons/svg/free.svg#cil-menu'></use>
                                </svg>
                            </button>
                            <a className='c-header-brand d-lg-none c-header-brand-sm-up-center' href='/'>
                                <LazyLoadImage className='c-header-brand-minimized c-d-dark-none' src='/img/logo.svg' width={'200px'} alt='AdhyaksaPedia' />
                            </a>
                            <ul className='c-header-nav ml-automin'></ul>
                            <ul className='c-header-nav d-md-down-none ulimagepencarian'>
                                <li>
                                    <a href='/'>
                                        <LazyLoadImage src='/img/logo.svg' className='headerlogo' alt='AdhyaksaPedia' />
                                    </a>
                                </li>
                                <li style={{ 'paddingLeft': '10px' }}>
                                    <div className='input-group divpencarian'>
                                        <input
                                            className='form-control'
                                            id='formpencarian'
                                            type='text'
                                            name='formpencarian'
                                            placeholder='Cari Sesuatu...'
                                            onKeyDown={handlePencarian}
                                            onChange={(e) => setPencarian(e.target.value)}
                                            value={pencarian}
                                        />
                                        <div className='input-group-append'>
                                            <span className='input-group-text spansearching'>
                                                <i className='cil-search'></i>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className='c-header-nav ml-auto'></ul>
                            <ul className='c-header-nav d-md-down-none listmenuheader'>
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/produk-hukum'>Produk Hukum</a></li>
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/berita'>Berita</a></li>
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/tanya-jaksa'>Tanya Jaksa</a></li>
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/event'>Event</a></li>
                                {auth?.data?.token ? (
                                    auth?.data?.status_verifikasi_akuns_id === 3 ? (
                                        <>
                                            <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#modalLapor' onClick={(e) => clickModalLapor(e)}>Lapor</a></li>
                                        </>
                                    ) : (
                                        <>
                                            <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#login' onClick={(e) => clickModalVerifikasiAkun(e)}>Lapor</a></li>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#login' onClick={(e) => clickModalLogin(e)}>Lapor</a></li>
                                    </>
                                )}
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/helpdesk'>Helpdesk?</a></li>
                            </ul>
                            <ul className='c-header-nav ullogin'>
                                {auth?.data?.token ? (
                                    <>
                                        <li className='c-header-nav-item dropdown d-md-down-none mx-2'>
                                            <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                                <LazyLoadImage src='/img/notificationbadge-white.svg' alt='notifikasi' />
                                            </a>
                                            <div className='dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0 wrappernotification'>
                                                <div className='dropdown-header'>
                                                    <p className='texttitlenotification'>Notifikasi</p>
                                                </div>
                                                <div className='breaknotif'></div>
                                                <div className='bodynotification'>
                                                    <div className='dropdown-header'>
                                                        <p className='titlewaktunotifikasi'>Hari ini</p>
                                                    </div>
                                                    {listNotifikasiMasyarakatData && listNotifikasiMasyarakatData.map((notifikasimasyarakat, no) => (
                                                        <div key={no} >
                                                            {notifikasimasyarakat?.hariini.length !== 0 ? (
                                                                notifikasimasyarakat?.hariini.map((notifikasimasyarakathariini) => (
                                                                    <div key={notifikasimasyarakathariini?.id} >
                                                                        {notifikasimasyarakathariini?.status_baca === false ? (
                                                                            <div className='dropdown-item new mb-1'>
                                                                                <a className='anostyle' href={notifikasimasyarakathariini?.url}>
                                                                                    <div className='boxtextnotifikasi'>
                                                                                        <p className='newnotifikasi'>Baru</p>
                                                                                        <p className='textnotifikasi'>
                                                                                            {notifikasimasyarakathariini?.deskripsi}
                                                                                        </p>
                                                                                        <p className='waktunotifikasi'>{waktuBerlalu(notifikasimasyarakathariini?.created_at)}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='dropdown-item mb-1'>
                                                                                <a className='anostyle' href={notifikasimasyarakathariini?.url}>
                                                                                    <div className='boxtextnotifikasi'>
                                                                                        <p className='textnotifikasi'>
                                                                                            {notifikasimasyarakathariini?.deskripsi}
                                                                                        </p>
                                                                                        <p className='waktunotifikasi'>{waktuBerlalu(notifikasimasyarakathariini?.created_at)}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))) : (
                                                                <div className='dropdown-item mb-1'>
                                                                    <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    <div className='dropdown-header'>
                                                        <p className='titlewaktunotifikasi'>Kemarin</p>
                                                    </div>
                                                    {listNotifikasiMasyarakatData && listNotifikasiMasyarakatData.map((notifikasimasyarakat, no) => (
                                                        <div key={no} >
                                                            {notifikasimasyarakat?.kemarin.length !== 0 ? (
                                                                notifikasimasyarakat?.kemarin.map((notifikasimasyarakatkemarin) => (
                                                                    <div key={notifikasimasyarakatkemarin?.id} >
                                                                        {notifikasimasyarakatkemarin?.status_baca === false ? (
                                                                            <div className='dropdown-item new mb-1'>
                                                                                <a className='anostyle' href={notifikasimasyarakatkemarin?.url}>
                                                                                    <div className='boxtextnotifikasi'>
                                                                                        <p className='newnotifikasi'>Baru</p>
                                                                                        <p className='textnotifikasi'>
                                                                                            {notifikasimasyarakatkemarin?.deskripsi}
                                                                                        </p>
                                                                                        <p className='waktunotifikasi'>{waktuBerlalu(notifikasimasyarakatkemarin?.created_at)}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='dropdown-item mb-1'>
                                                                                <a className='anostyle' href={notifikasimasyarakatkemarin?.url}>
                                                                                    <div className='boxtextnotifikasi'>
                                                                                        <p className='textnotifikasi'>
                                                                                            {notifikasimasyarakatkemarin?.deskripsi}
                                                                                        </p>
                                                                                        <p className='waktunotifikasi'>{waktuBerlalu(notifikasimasyarakatkemarin?.created_at)}</p>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ))) : (
                                                                <div className='dropdown-item mb-1'>
                                                                    <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='dropdown-item border-top' style={{ 'backgroundColor': '#fff' }}>
                                                    <div className='col-sm-4 left-align'>
                                                        <a href='/notifikasi' className='textfooternotif'>Lihat Semua</a>
                                                    </div>
                                                    <div className='col-sm-8 right-align'>
                                                        <button type="button" className='btnnostyle textfooternotif' onClick={handleUpdateStatusNotifikasi}>Tandai sudah dibaca semua</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='c-header-nav-item dropdown mr-2'>
                                            <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                                <div className='c-avatar'>
                                                    {auth?.data?.foto === '' ? (
                                                        <LazyLoadImage className='c-avatar-img' src='/assets/img/avatars/default.png' alt='AdhyaksaPedia' />
                                                    ) : (
                                                        <LazyLoadImage className='c-avatar-img' src={auth?.data?.foto} alt='AdhyaksaPedia' />
                                                    )}
                                                </div>
                                            </a>
                                            <div className='dropdown-menu dropdown-menu-right pt-0'>
                                                <a className='dropdown-item customhref' href='/dashboard/member/profil'>
                                                    <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                                    &nbsp; Profil
                                                </a>
                                                <div className='dropdown-divider dropdown-divider-custom'></div>
                                                <a className='dropdown-item customhref' href='#keluar' onClick={(e) => clickModalKeluar(e)}>
                                                    <LazyLoadImage src='/img/sc-log-in-circle.svg' alt='logout' />
                                                    &nbsp; Keluar
                                                </a>
                                            </div>
                                        </li>
                                    </>
                                ) : (
                                    <li className='c-header-nav-item dropdown d-md-down-none mx-2 mr-4'>
                                        <a className='textlogin' href='#login' onClick={(e) => clickModalLogin(e)}>Login</a>
                                    </li>
                                )}
                            </ul>
                        </header>
                        <div className='c-sidebar c-sidebar-blue c-sidebar-fixed c-sidebar-lg-show' id='sidebar'>
                            <div className='sidebar-header'>
                                <button className='btncloseheader btn-close' type='button' data-coreui-dismiss='offcanvas' aria-label='Close' onClick={closeSidebar}></button>
                            </div>
                            <ul className='c-sidebar-nav c-sidebar-nav-member mt-5'>
                                <li className='c-sidebar-nav-item'>
                                    <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/member/profil' ? 'c-active' : '')} href='/dashboard/member/profil'>
                                        <LazyLoadImage className='iconsidebar' src='/img/bx-user-circle.svg' alt='dashboard' /> Profil
                                    </a>
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/member/riwayat-helpdesk' ? 'c-active' : '')} href='/dashboard/member/riwayat-helpdesk'>
                                        <LazyLoadImage className='iconsidebar' src='/img/sc-helpdesk.svg' alt='dashboard' /> Riwayat Helpdesk
                                    </a>
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/member/pengaduan' ? 'c-active' : '')} href='/dashboard/member/pengaduan'>
                                        <LazyLoadImage className='iconsidebar' src='/img/sc-pengaduan.svg' alt='dashboard' /> Pengaduan
                                    </a>
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/member/kritik-saran' ? 'c-active' : '')} href='/dashboard/member/kritik-saran'>
                                        <LazyLoadImage className='iconsidebar' src='/img/sc-critics.svg' alt='dashboard' /> Kritik & Saran
                                    </a>
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className={'c-sidebar-nav-link ' + (window.location.pathname === '/dashboard/member/tanya-jaksa' ? 'c-active' : '')} href='/dashboard/member/tanya-jaksa'>
                                        <LazyLoadImage className='iconsidebar' src='/img/bx-help-circle.svg' alt='dashboard' /> Tanya Jaksa
                                    </a>
                                </li>
                                <li className='breaksidebar'>
                                    <hr />
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className='c-sidebar-nav-link' href='#editkatasandi' onClick={(e) => clickModalEditKataSandi(e)}>
                                        <LazyLoadImage className='iconsidebar' src='/img/sc-lock-open.svg' alt='dashboard' /> Ubah Katasandi
                                    </a>
                                </li>
                                <li className='c-sidebar-nav-item'>
                                    <a className='c-sidebar-nav-link' href='#keluar' onClick={(e) => clickModalKeluar(e)}>
                                        <LazyLoadImage className='iconsidebar' src='/img/sc-log-in-circle.svg' alt='dashboard' /> Keluar
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='c-body'>
                            <main className='c-main'>
                                <div className='container-fluid'>
                                    <div className='container-fluid-custom'>
                                        <div className='fade-in'>
                                            <div className='row'>
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                            {totalPolingData !== 0 &&
                                <div className='polingbutton'>
                                    {showIconPoling &&
                                        <>
                                            <button type='button' className='btnnostyle btnfloating' onClick={clickModalPoling}>
                                                <LazyLoadImage src='/img/popuppoling.svg' alt='poling' className='imgresponsive' />
                                            </button>
                                            <button type='button' className='btnnostyle btnfloating' onClick={closeIconPoling}>
                                                <LazyLoadImage src='/img/sc-x-circle.svg' alt='poling' className='imgresponsive' />
                                            </button>
                                        </>
                                    }
                                </div>
                            }
                            <footer className='c-footer profilfooter'>
                                <div>
                                    <a href='/'>
                                        <LazyLoadImage src='/img/logo.svg' alt='adhyaksapedia' className='imagefooter' />
                                    </a>
                                    <p className='textfooter'>
                                        {pengaturanUmumData?.footer}
                                    </p>
                                </div>
                                <div className='mfs-auto'>
                                    <div className='row'>
                                        <div className='footerlink col-md-12'>
                                            <ul className='c-footer-link'>
                                                <li>
                                                    {pengaturanUmumData?.url_instagram !== '' &&
                                                        <a href={pengaturanUmumData?.url_instagram}>
                                                            <div className='col-md-1'>
                                                                <LazyLoadImage src='/img/sc-instagram.svg' alt='instagram' />
                                                            </div>
                                                        </a>
                                                    }
                                                </li>
                                                <li>
                                                    {pengaturanUmumData?.url_facebook !== '' &&
                                                        <a href={pengaturanUmumData?.url_facebook}>
                                                            <div className='col-md-1'>
                                                                <LazyLoadImage src='/img/sc-facebook.svg' alt='facebook' />
                                                            </div>
                                                        </a>
                                                    }
                                                </li>
                                                <li>
                                                    {pengaturanUmumData?.url_twitter !== '' &&
                                                        <a href={pengaturanUmumData?.url_twitter}>
                                                            <div className='col-md-1'>
                                                                <LazyLoadImage src='/img/sc-twitter.svg' alt='twitter' />
                                                            </div>
                                                        </a>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='copyrightfooter col-md-12'>
                                            <p className='copyright'><b className='texthukum'>Adhyaksa</b><b className='textpedia'>Pedia</b>&nbsp; &#8226; &nbsp;© {tahunsekarang} All Rights Reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                            {totalPolingData !== 0 &&
                                <ModalPolingComponent
                                    showmodal={showModalPoling}
                                    hidemodal={hideModalPoling}
                                />
                            }
                            <ModalLoginComponent
                                showmodal={showModalLogin}
                                hidemodal={hideModalLogin}
                            />
                            <ModalEditKataSandiComponent
                                showmodal={showModalEditKataSandi}
                                hidemodal={hideModalEditKataSandi}
                            />
                            <ModalLaporComponent
                                showmodal={showModalLapor}
                                hidemodal={hideModalLapor}
                            />
                            <ModalKeluarComponent
                                showmodal={showModalKeluar}
                                hidemodal={hideModalKeluar}
                            />
                            <ModalVerifikasiAkunComponent
                                showmodal={showModalVerifikasiAkun}
                                hidemodal={hideModalVerifikasiAkun}
                                onhide={onHideModalVerifikasiAkun}
                            />
                        </div>
                    </div >
                </>
            );
        }
    } else if (segment1 !== 'lupa-katasandi' && segment1 !== 'hapus-akun' && segment1 !== 'verifikasi-kode' && segment1 !== 'reset-katasandi' && segment2 !== 'login' && segment1 !== 'admin') {
        return (
            <>
                <header className='c-header c-header-fixed'>
                    <button className='c-header-toggler c-class-toggler d-lg-none mfe-auto' type='button' onClick={openSidebar}>
                        <svg className='c-icon c-icon-lg'>
                            <use xlinkHref='/vendors/@coreui/icons/svg/free.svg#cil-menu'></use>
                        </svg>
                    </button>
                    <a className='c-header-brand d-lg-none c-header-brand-sm-up-center' href='/'>
                        <LazyLoadImage className='c-header-brand-minimized c-d-dark-none' src='/img/logo.svg' width={'200px'} alt='AdhyaksaPedia' />
                    </a>
                    <ul className='c-header-nav ml-automin'></ul>
                    <ul className='c-header-nav d-md-down-none ulimagepencarian'>
                        <li>
                            <a href='/'>
                                <LazyLoadImage src='/img/logo.svg' className='headerlogo' alt='AdhyaksaPedia' />
                            </a>
                        </li>
                        <li style={{ 'paddingLeft': '10px' }}>
                            <div className='input-group divpencarian'>
                                <input
                                    className='form-control'
                                    id='formpencarian'
                                    type='text'
                                    name='formpencarian'
                                    placeholder='Cari Sesuatu...'
                                    onKeyDown={handlePencarian}
                                    onChange={(e) => setPencarian(e.target.value)}
                                    value={pencarian}
                                />
                                <div className='input-group-append'>
                                    <span className='input-group-text spansearching'>
                                        <i className='cil-search'></i>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className='c-header-nav ml-auto'></ul>
                    <ul className='c-header-nav d-md-down-none listmenuheader'>
                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/produk-hukum'>Produk Hukum</a></li>
                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/berita'>Berita</a></li>
                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/tanya-jaksa'>Tanya Jaksa</a></li>
                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/event'>Event</a></li>
                        {auth?.data?.token ? (
                            auth?.data?.status_verifikasi_akuns_id === 3 ? (
                                <>
                                    <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#modalLapor' onClick={(e) => clickModalLapor(e)}>Lapor</a></li>
                                </>
                            ) : (
                                <>
                                    <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#login' onClick={(e) => clickModalVerifikasiAkun(e)}>Lapor</a></li>
                                </>
                            )
                        ) : (
                            <>
                                <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='#login' onClick={(e) => clickModalLogin(e)}>Lapor</a></li>
                            </>
                        )}
                        <li className='c-header-nav-item px-2'><a className='c-header-nav-link' href='/helpdesk'>Helpdesk?</a></li>
                    </ul>
                    <ul className='c-header-nav ullogin'>
                        {auth?.data?.token ? (
                            <>
                                <li className='c-header-nav-item dropdown d-md-down-none mx-2'>
                                    <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                        <LazyLoadImage src='/img/notificationbadge-white.svg' alt='notifikasi' />
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0 wrappernotification'>
                                        <div className='dropdown-header'>
                                            <p className='texttitlenotification'>Notifikasi</p>
                                        </div>
                                        <div className='breaknotif'></div>
                                        <div className='bodynotification'>
                                            <div className='dropdown-header'>
                                                <p className='titlewaktunotifikasi'>Hari ini</p>
                                            </div>
                                            {listNotifikasiMasyarakatData && listNotifikasiMasyarakatData.map((notifikasiadmin, no) => (
                                                <div key={no} >
                                                    {notifikasiadmin?.hariini.length !== 0 ? (
                                                        notifikasiadmin?.hariini.map((notifikasiadminhariini) => (
                                                            <div key={notifikasiadminhariini?.id} >
                                                                {notifikasiadminhariini?.status_baca === false ? (
                                                                    <div className='dropdown-item new mb-1'>
                                                                        <a className='anostyle' href={notifikasiadminhariini?.url}>
                                                                            <div className='boxtextnotifikasi'>
                                                                                <p className='newnotifikasi'>Baru</p>
                                                                                <p className='textnotifikasi'>
                                                                                    {notifikasiadminhariini?.deskripsi}
                                                                                </p>
                                                                                <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminhariini?.created_at)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ) : (
                                                                    <div className='dropdown-item mb-1'>
                                                                        <a className='anostyle' href={notifikasiadminhariini?.url}>
                                                                            <div className='boxtextnotifikasi'>
                                                                                <p className='textnotifikasi'>
                                                                                    {notifikasiadminhariini?.deskripsi}
                                                                                </p>
                                                                                <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminhariini?.created_at)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))) : (
                                                        <div className='dropdown-item mb-1'>
                                                            <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <div className='dropdown-header'>
                                                <p className='titlewaktunotifikasi'>Kemarin</p>
                                            </div>
                                            {listNotifikasiMasyarakatData && listNotifikasiMasyarakatData.map((notifikasiadmin, no) => (
                                                <div key={no} >
                                                    {notifikasiadmin?.kemarin.length !== 0 ? (
                                                        notifikasiadmin?.kemarin.map((notifikasiadminkemarin) => (
                                                            <div key={notifikasiadminkemarin?.id} >
                                                                {notifikasiadminkemarin?.status_baca === false ? (
                                                                    <div className='dropdown-item new mb-1'>
                                                                        <a className='anostyle' href={notifikasiadminkemarin?.url}>
                                                                            <div className='boxtextnotifikasi'>
                                                                                <p className='newnotifikasi'>Baru</p>
                                                                                <p className='textnotifikasi'>
                                                                                    {notifikasiadminkemarin?.deskripsi}
                                                                                </p>
                                                                                <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminkemarin?.created_at)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ) : (
                                                                    <div className='dropdown-item mb-1'>
                                                                        <a className='anostyle' href={notifikasiadminkemarin?.url}>
                                                                            <div className='boxtextnotifikasi'>
                                                                                <p className='textnotifikasi'>
                                                                                    {notifikasiadminkemarin?.deskripsi}
                                                                                </p>
                                                                                <p className='waktunotifikasi'>{waktuBerlalu(notifikasiadminkemarin?.created_at)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))) : (
                                                        <div className='dropdown-item mb-1'>
                                                            <p className='textnotifikasi'>Tidak ada notifikasi</p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='dropdown-item border-top' style={{ 'backgroundColor': '#fff' }}>
                                            <div className='col-sm-4 left-align'>
                                                <a href='/notifikasi' className='textfooternotif'>Lihat Semua</a>
                                            </div>
                                            <div className='col-sm-8 right-align'>
                                                <button type="button" className='btnnostyle textfooternotif' onClick={handleUpdateStatusNotifikasi}>Tandai sudah dibaca semua</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='c-header-nav-item dropdown mr-2'>
                                    <a className='c-header-nav-link' data-toggle='dropdown' href='#top' role='button' aria-haspopup='true' aria-expanded='false'>
                                        <div className='c-avatar'>
                                            {auth?.data?.foto === '' ? (
                                                <LazyLoadImage className='c-avatar-img' src='/assets/img/avatars/default.png' alt='AdhyaksaPedia' />
                                            ) : (
                                                <LazyLoadImage className='c-avatar-img' src={auth?.data?.foto} alt='AdhyaksaPedia' />
                                            )}
                                        </div>
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-right pt-0'>
                                        <a className='dropdown-item customhref' href='/dashboard/member/profil'>
                                            <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                            &nbsp; Profil
                                        </a>
                                        <div className='dropdown-divider dropdown-divider-custom'></div>
                                        <a className='dropdown-item customhref' href='#keluar' onClick={(e) => clickModalKeluar(e)}>
                                            <LazyLoadImage src='/img/sc-log-in-circle.svg' alt='logout' />
                                            &nbsp; Keluar
                                        </a>
                                    </div>
                                </li>
                            </>
                        ) : (
                            <li className='c-header-nav-item dropdown d-md-down-none mx-2 mr-4'>
                                <a className='textlogin' href='#login' onClick={(e) => clickModalLogin(e)}>Login</a>
                            </li>
                        )}
                    </ul>
                </header>
                {segment1 !== 'produk-hukum' && segment1 !== 'helpdesk' && segment1 !== 'pencarian' &&
                    <div className='c-sidebar c-sidebar-blue c-sidebar-fixed' id='sidebar'>
                        <div className='sidebar-header'>
                            <button className='btncloseheader btn-close' type='button' data-coreui-dismiss='offcanvas' aria-label='Close' onClick={closeSidebar}></button>
                        </div>
                        <ul className='c-sidebar-nav c-sidebar-nav-member'>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/produk-hukum'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Produk Hukum' data-original-title='Produk Hukum'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-file'></use>
                                    </svg> Produk Hukum
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/berita'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Berita' data-original-title='Berita'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-newspaper'></use>
                                    </svg> Berita
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/tanya-jaksa'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Tanya Jaksa' data-original-title='Tanja Jaksa'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-hand-point-up'></use>
                                    </svg> Tanya Jaksa
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/event'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Event' data-original-title='Event'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-calendar'></use>
                                    </svg> Event
                                </a>
                            </li>
                            {auth?.data?.token ? (
                                auth?.data?.status_verifikasi_akuns_id === 3 ? (
                                    <>
                                        <li className='c-sidebar-nav-item'>
                                            <a className='c-sidebar-nav-link' href='#lapor' onClick={(e) => clickModalLapor(e)}>
                                                <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                    <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                                </svg> Lapor
                                            </a>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className='c-sidebar-nav-item'>
                                            <a className='c-sidebar-nav-link' href="#login" onClick={(e) => clickModalVerifikasiAkun(e)}>
                                                <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                    <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                                </svg> Lapor
                                            </a>
                                        </li>
                                    </>
                                )
                            ) : (
                                <>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href="#login" onClick={(e) => clickModalLogin(e)}>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                            </svg> Lapor
                                        </a>
                                    </li>
                                </>
                            )}
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/helpdesk'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Helpdesk' data-original-title='Helpdesk'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-screen-desktop'></use>
                                    </svg> Helpdesk?
                                </a>
                            </li>
                            {auth?.data?.token ? (
                                <>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href='/notifikasi'>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Profil' data-original-title='Profil'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-bell'></use>
                                            </svg> Notifikasi
                                        </a>
                                    </li>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href='/dashboard/member/profil'>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Profil' data-original-title='Profil'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-user'></use>
                                            </svg> Profil
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <li className='c-sidebar-nav-item'>
                                    <a className='c-sidebar-nav-link' href='#login' onClick={(e) => clickModalLogin(e)}>
                                        <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Login' data-original-title='Login'>
                                            <use xlinkHref='/assets/icons/coreui/free.svg#cil-https'></use>
                                        </svg> Login
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                {segment2 === 'detail' &&
                    <div className='c-sidebar c-sidebar-blue c-sidebar-fixed' id='sidebar'>
                        <div className='sidebar-header'>
                            <button className='btncloseheader btn-close' type='button' data-coreui-dismiss='offcanvas' aria-label='Close' onClick={closeSidebar}></button>
                        </div>
                        <ul className='c-sidebar-nav c-sidebar-nav-member'>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/produk-hukum'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Produk Hukum' data-original-title='Produk Hukum'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-file'></use>
                                    </svg> Produk Hukum
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/berita'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Berita' data-original-title='Berita'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-newspaper'></use>
                                    </svg> Berita
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/tanya-jaksa'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Tanya Jaksa' data-original-title='Tanja Jaksa'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-hand-point-up'></use>
                                    </svg> Tanya Jaksa
                                </a>
                            </li>
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/event'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Event' data-original-title='Event'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-calendar'></use>
                                    </svg> Event
                                </a>
                            </li>
                            {auth?.data?.token ? (
                                auth?.data?.status_verifikasi_akuns_id === 3 ? (
                                    <>
                                        <li className='c-sidebar-nav-item'>
                                            <a className='c-sidebar-nav-link' href='#lapor' onClick={(e) => clickModalLapor(e)}>
                                                <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                    <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                                </svg> Lapor
                                            </a>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className='c-sidebar-nav-item'>
                                            <a className='c-sidebar-nav-link' href="#login" onClick={(e) => clickModalVerifikasiAkun(e)}>
                                                <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                    <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                                </svg> Lapor
                                            </a>
                                        </li>
                                    </>
                                )
                            ) : (
                                <>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href="#login" onClick={(e) => clickModalLogin(e)}>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Lapor' data-original-title='Lapor'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-info'></use>
                                            </svg> Lapor
                                        </a>
                                    </li>
                                </>
                            )}
                            <li className='c-sidebar-nav-item'>
                                <a className='c-sidebar-nav-link' href='/helpdesk'>
                                    <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Helpdesk' data-original-title='Helpdesk'>
                                        <use xlinkHref='/assets/icons/coreui/free.svg#cil-screen-desktop'></use>
                                    </svg> Helpdesk?
                                </a>
                            </li>
                            {auth?.data?.token ? (
                                <>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href='/notifikasi'>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Profil' data-original-title='Profil'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-bell'></use>
                                            </svg> Notifikasi
                                        </a>
                                    </li>
                                    <li className='c-sidebar-nav-item'>
                                        <a className='c-sidebar-nav-link' href='/dashboard/member/profil'>
                                            <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Profil' data-original-title='Profil'>
                                                <use xlinkHref='/assets/icons/coreui/free.svg#cil-user'></use>
                                            </svg> Profil
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <li className='c-sidebar-nav-item'>
                                    <a className='c-sidebar-nav-link' href='#login' onClick={(e) => clickModalLogin(e)}>
                                        <svg className='c-sidebar-nav-icon' data-toggle='tooltip' data-placement='right' title='Login' data-original-title='Login'>
                                            <use xlinkHref='/assets/icons/coreui/free.svg#cil-https'></use>
                                        </svg> Login
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                <div className='c-wrapper'>
                    <div className='c-body'>
                        <main className='c-main'>
                            {children}
                        </main>
                        {totalPolingData !== 0 &&
                            <div className='polingbutton'>
                                {showIconPoling &&
                                    <>
                                        <button type='button' className='btnnostyle btnfloating' onClick={clickModalPoling}>
                                            <LazyLoadImage src='/img/popuppoling.svg' alt='poling' className='imgresponsive' />
                                        </button>
                                        <button type='button' className='btnnostyle btnfloating' onClick={closeIconPoling}>
                                            <LazyLoadImage src='/img/sc-x-circle.svg' alt='poling' className='imgresponsive' />
                                        </button>
                                    </>
                                }
                            </div>
                        }
                        <footer className='c-footer profilfooter'>
                            <div className='col-sm-12 col-md-6'>
                                <a href='/'>
                                    <LazyLoadImage src='/img/logo.svg' alt='adhyaksapedia' className='imagefooter' />
                                </a>
                                <p className='textfooter'>
                                    {pengaturanUmumData?.footer}
                                </p>
                            </div>
                            <div className='mfs-auto col-sm-12 col-md-6'>
                                <div className='row'>
                                    <div className='footerlink col-md-12'>
                                        <ul className='c-footer-link'>
                                            <li>
                                                {pengaturanUmumData?.url_instagram !== '' &&
                                                    <a href={pengaturanUmumData?.url_instagram}>
                                                        <div className='col-md-1'>
                                                            <LazyLoadImage src='/img/sc-instagram.svg' alt='instagram' />
                                                        </div>
                                                    </a>
                                                }
                                            </li>
                                            <li>
                                                {pengaturanUmumData?.url_facebook !== '' &&
                                                    <a href={pengaturanUmumData?.url_facebook}>
                                                        <div className='col-md-1'>
                                                            <LazyLoadImage src='/img/sc-facebook.svg' alt='facebook' />
                                                        </div>
                                                    </a>
                                                }
                                            </li>
                                            <li>
                                                {pengaturanUmumData?.url_twitter !== '' &&
                                                    <a href={pengaturanUmumData?.url_twitter}>
                                                        <div className='col-md-1'>
                                                            <LazyLoadImage src='/img/sc-twitter.svg' alt='twitter' />
                                                        </div>
                                                    </a>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='copyrightfooter col-md-12'>
                                        <p className='copyright'><b className='texthukum'>Adhyaksa</b><b className='textpedia'>Pedia</b>&nbsp; &#8226; &nbsp;© {tahunsekarang} All Rights Reserved</p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        {totalPolingData !== 0 &&
                            <ModalPolingComponent
                                showmodal={showModalPoling}
                                hidemodal={hideModalPoling}
                            />
                        }
                        <ModalLoginComponent
                            showmodal={showModalLogin}
                            hidemodal={hideModalLogin}
                        />
                        <ModalEditKataSandiComponent
                            showmodal={showModalEditKataSandi}
                            hidemodal={hideModalEditKataSandi}
                        />
                        <ModalLaporComponent
                            showmodal={showModalLapor}
                            hidemodal={hideModalLapor}
                        />
                        <ModalKeluarComponent
                            showmodal={showModalKeluar}
                            hidemodal={hideModalKeluar}
                        />
                        <ModalVerifikasiAkunComponent
                            showmodal={showModalVerifikasiAkun}
                            hidemodal={hideModalVerifikasiAkun}
                            onhide={onHideModalVerifikasiAkun}
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}

export default Layout