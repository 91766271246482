import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useKataSandiEdit = (data) => {
    const [errorKataSandiEdit, setErrorKataSandiEdit] = useState('');
    const [successKataSandiEdit, setSuccessKataSandiEdit] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorKataSandiEdit('');
                const API_URL = 'profil/editakun';
                const fetchData = async () => {
                    await useAxios.patch(API_URL,
                        JSON.stringify({ username: data?.username, password: data?.password, password_lama: data?.password_lama }),
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        localStorage.setItem('user', JSON.stringify(res.data));
                        setSuccessKataSandiEdit(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorKataSandiEdit('API edit hierarki peraturan tidak ada response dari server');
                        } else {
                            setErrorKataSandiEdit(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorKataSandiEdit, successKataSandiEdit];
}

export default useKataSandiEdit;