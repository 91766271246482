import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const usePengaduanMasyarakatUploadFile = (data) => {
    const [errorPengaduanMasyarakatUploadFile, setErrorPengaduanMasyarakatUploadFile] = useState('');
    const [successPengaduanMasyarakatUploadFile, setSuccessPengaduanMasyarakatUploadFile] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorPengaduanMasyarakatUploadFile('');
                setSuccessPengaduanMasyarakatUploadFile('');
                const API_URL = 'pengaduanmasyarakat/upload/file';
                const formdata = new FormData();
                formdata.append('id',data?.id);
                formdata.append('file',data?.file);
                formdata.append('tipe', data?.tipefile);
                formdata.append('ukuran', data?.ukuranfile);
                const fetchData = async () => {
                    await useAxios.patch(API_URL,
                        formdata,
                        {
                            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setSuccessPengaduanMasyarakatUploadFile(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorPengaduanMasyarakatUploadFile('API upload file pengaduan masyarakat tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('admin');
                            window.location.href = '/admin/login';
                        } else {
                            setErrorPengaduanMasyarakatUploadFile(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorPengaduanMasyarakatUploadFile,successPengaduanMasyarakatUploadFile];
}

export default usePengaduanMasyarakatUploadFile;