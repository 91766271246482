import { React, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalSuksesComponent from '../sukses/ModalSukses';
import useLogout from '../../../api/useLogout';

const ModalKeluar = ({ showmodal, hidemodal }) => {
    const [showModalSukses, setShowModalSukses] = useState(false);
    const [postLogout, setPostLogout] = useState([]);
    const [errorLogout] = useLogout(postLogout);

    const clickHandleSukses = (e) => {
        e.preventDefault();
        hidemodal();
        setShowModalSukses(true);
    }

    const hideModalSukses = () => {
        const authuser = JSON.parse(localStorage.getItem('user'));

        const logoutdata = {
            token: authuser?.data?.token,
        };
        setPostLogout(logoutdata);

        
        if (errorLogout === "") {
            localStorage.removeItem('user');
            window.location.href = '/';
        } else {
            window.location = window.location.href;
        }
    }

    return (
        <>
            <Modal
                aria-labelledby='modalKeluarLabel'
                role='dialog'
                tabIndex='-1'
                centered
                onHide={hidemodal}
                show={showmodal}
            >
                <div className='modal-header modalheaderprofil'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titleprofil'>Keluar</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <p className='textkeluar'>Yakin ingin keluar?</p>
                    <div className='col-12 center-align'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Tidak</button>
                            </div>
                            <div className='col-sm-6'>
                                <button className='btn btn-lg btn-primary btnmasuklogin' onClick={clickHandleSukses}>Ya</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalSuksesComponent
                showmodal={showModalSukses}
                hidemodal={hideModalSukses}
                textsukses='Kamu berhasil keluar'
            />
        </>
    )
}

export default ModalKeluar