import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const usePengaduanMasyarakatTambah = (data) => {
    const [idPengaduanMasyarakat, setIdPengaduanMasyarakat] = useState(0);
    const [errorPengaduanMasyarakatTambah, setErrorPengaduanMasyarakatTambah] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorPengaduanMasyarakatTambah('');
                const API_URL = 'pengaduanmasyarakat/tambah';
                const fetchData = async () => {
                    await useAxios.post(API_URL,
                        JSON.stringify({
                            instansis_id: data?.instansis_id,
                            kota_kabupatens_id: data?.kota_kabupatens_id,
                            kategori_laporan_subs_id: data?.kategori_laporan_subs_id,
                            tanggal: data?.tanggal,
                            judul: data?.judul,
                            konten: data?.konten
                        }),
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setIdPengaduanMasyarakat(res?.data?.data?.ID);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorPengaduanMasyarakatTambah('API tambah pengaduan masyarakat tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('user');
                            window.location.href = '/';
                        } else {
                            setErrorPengaduanMasyarakatTambah(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [idPengaduanMasyarakat, errorPengaduanMasyarakatTambah];
}

export default usePengaduanMasyarakatTambah;