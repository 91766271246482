import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const usePolingKoresponden = (data) => {
    const [errorPolingKoresponden, setErrorPolingKoresponden] = useState('');
    const [successPolingKoresponden, setSuccessPolingKoresponden] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorPolingKoresponden('');
                setSuccessPolingKoresponden('');
                const API_URL = 'poling/koresponden';
                const fetchData = async () => {
                    await useAxios.post(API_URL,
                        JSON.stringify(data),
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setSuccessPolingKoresponden(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorPolingKoresponden('API koresponden poling tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('user');
                            window.location.href = '/';
                        } else {
                            setErrorPolingKoresponden(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorPolingKoresponden, successPolingKoresponden];
}

export default usePolingKoresponden;