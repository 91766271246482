import Modal from 'react-bootstrap/Modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalVerifikasiAkun = ({ showmodal, hidemodal, onhide }) => {
    return (
        <Modal
            aria-labelledby='modalVerifikasiAkunLabel'
            data-backdrop='static'
            data-keyboard='false'
            role='dialog'
            tabIndex='-1'
            centered
            onHide={onhide}
            show={showmodal}
        >
            <div className='modal-header modalheaderlogin'>
                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                    <h4 className='modal-titlelogin'>Maaf !</h4>
                </div>
            </div>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        <LazyLoadImage src='/img/requiredauth.svg' alt='requiredauth' className='imgresponsive' />
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        <p className='textrequired'>Akun Anda harus terverifikasi terlebih dahulu</p>
                        <p className='textsubrequired'>agar dapat mengakses fitur ini.</p>
                    </div>
                    <div className='col-md-12 center-align'>
                        <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={hidemodal}>Klik disini untuk cek status akun</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalVerifikasiAkun