import { Routes, Route, Navigate } from 'react-router-dom';
import { React, lazy } from 'react';

const Beranda = lazy(() => import('./guest/Beranda'));
const ProdukHukum = lazy(() => import('./guest/ProdukHukum'));
const ProdukHukumDetail = lazy(() => import('./guest/ProdukHukumDetail'));
const Berita = lazy(() => import('./guest/Berita'));
const BeritaDetail = lazy(() => import('./guest/BeritaDetail'));
const BeritaLihatSemua = lazy(() => import('./guest/BeritaLihatSemua'));
const TanyaJaksa = lazy(() => import('./guest/TanyaJaksa'));
const TanyaJaksaDetail = lazy(() => import('./guest/TanyaJaksaDetail'));
const TanyaJaksaLihatSemua = lazy(() => import('./guest/TanyaJaksaLihatSemua'));
const Event = lazy(() => import('./guest/Event'));
const EventDetail = lazy(() => import('./guest/EventDetail'));
const LupaKataSandi = lazy(() => import('./guest/LupaKataSandi'));
const HapusAkun = lazy(() => import('./guest/HapusAkun'));
const VerifikasiKode = lazy(() => import('./guest/VerifikasiKode'));
const ResetKataSandi = lazy(() => import('./guest/ResetKataSandi'));
const Pencarian = lazy(() => import('./guest/Pencarian'));
const Notifikasi = lazy(() => import('./member/Notifikasi'));
const KebijakanPrivasi = lazy(() => import('./KebijakanPrivasi'));

const ProfilMember = lazy(() => import('./member/ProfilMember'));
const Helpdesk = lazy(() => import('./member/Helpdesk'));
const HelpdeskKategori = lazy(() => import('./member/HelpdeskKategori'));
const RiwayatHelpdesk = lazy(() => import('./member/RiwayatHelpdesk'));
const Pengaduan = lazy(() => import('./member/Pengaduan'));
const KritikSaran = lazy(() => import('./member/KritikSaran'));
const MemberTanyaJaksa = lazy(() => import('./member/TanyaJaksa'));

const LoginAdmin = lazy(() => import('./admin/LoginAdmin'));
const LoginAdminSSOCallback = lazy(() => import('./admin/LoginAdminSSOCallback'));
const LogoutAdmin = lazy(() => import('./admin/LogoutAdmin'));
const ProfilAdmin = lazy(() => import('./admin/ProfilAdmin'));
const PencarianAdmin = lazy(() => import('./admin/PencarianAdmin'));
const NotifikasiAdmin = lazy(() => import('./admin/NotifikasiAdmin'));
const DashboardAdmin = lazy(() => import('./admin/DashboardAdmin'));
const BeritaAdmin = lazy(() => import('./admin/BeritaAdmin'));
const BeritaAdminTambah = lazy(() => import('./admin/BeritaAdminTambah'));
const BeritaAdminUbah = lazy(() => import('./admin/BeritaAdminUbah'));
const KategoriBeritaAdmin = lazy(() => import('./admin/KategoriBeritaAdmin'));
const KategoriProdukHukumAdmin = lazy(() => import('./admin/KategoriProdukHukumAdmin'));
const HierarkiPeraturanAdmin = lazy(() => import('./admin/HierarkiPeraturanAdmin'));
const ProdukHukumAdmin = lazy(() => import('./admin/ProdukHukumAdmin'));
const ProdukHukumAdminTambah = lazy(() => import('./admin/ProdukHukumAdminTambah'));
const ProdukHukumAdminUbah = lazy(() => import('./admin/ProdukHukumAdminUbah'));
const PolingAdmin = lazy(() => import('./admin/PolingAdmin'));
const EventAdmin = lazy(() => import('./admin/EventAdmin'));
const EventAdminTambah = lazy(() => import('./admin/EventAdminTambah'));
const EventAdminUbah = lazy(() => import('./admin/EventAdminUbah'));
const StatistikAdmin = lazy(() => import('./admin/StatistikAdmin'));
const TanyaJaksaAdmin = lazy(() => import('./admin/TanyaJaksaAdmin'));
const TanyaJaksaAdminTambah = lazy(() => import('./admin/TanyaJaksaAdminTambah'));
const TanyaJaksaAdminTanggapan = lazy(() => import('./admin/TanyaJaksaAdminTanggapan'));
const TanyaJaksaAdminUbah = lazy(() => import('./admin/TanyaJaksaAdminUbah'));
const PengaduanMasyarakatAdmin = lazy(() => import('./admin/PengaduanMasyarakatAdmin'));
const KategoriFaqAdmin = lazy(() => import('./admin/KategoriFaqAdmin'));
const FaqAdmin = lazy(() => import('./admin/FaqAdmin'));
const FaqAdminTambah = lazy(() => import('./admin/FaqAdminTambah'));
const FaqAdminUbah = lazy(() => import('./admin/FaqAdminUbah'));
const HelpdeskAdmin = lazy(() => import('./admin/HelpdeskAdmin'));
const KritikSaranAdmin = lazy(() => import('./admin/KritikSaranAdmin'));
const LaporanAdmin = lazy(() => import('./admin/LaporanAdmin'));
const MasyarakatAdmin = lazy(() => import('./admin/MasyarakatAdmin'));
const MasyarakatVerifikasiAdmin = lazy(() => import('./admin/MasyarakatVerifikasiAdmin'));
const InstansiAdmin = lazy(() => import('./admin/InstansiAdmin'));
const KategoriLaporanAdmin = lazy(() => import('./admin/KategoriLaporanAdmin'));
const AnggotaAdmin = lazy(() => import('./admin/AnggotaAdmin'));
const AnggotaNonAktifAdmin = lazy(() => import('./admin/AnggotaNonAktifAdmin'));
const PengaturanUmumAdmin = lazy(() => import('./admin/PengaturanUmumAdmin'));
const LogAktivitasAnggotaAdmin = lazy(() => import('./admin/LogAktivitasAnggotaAdmin'));
const LogAktivitasMasyarakatAdmin = lazy(() => import('./admin/LogAktivitasMasyarakatAdmin'));

const TidakDitemukan = lazy(() => import('./TidakDitemukan'));
const RequireAuthTipeUser = lazy(() => import('../components/RequireAuthTipeUser'));
const RequireAuth = lazy(() => import('../components/RequireAuth'));
const RequireAuthAdmin = lazy(() => import('../components/RequireAuthAdmin'));

const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Beranda />} />
            <Route path='/'element={<Beranda />} />
            <Route path='/beranda' element={<Beranda />} />
            <Route path='/produk-hukum' element={<ProdukHukum />} />
            <Route path='/produk-hukum/detail/:slug' element={<ProdukHukumDetail />} />
            <Route path='/berita' element={<Berita />} />
            <Route path='/berita/detail/:slug' element={<BeritaDetail />} />
            <Route path='/berita/kategori/:slug' element={<BeritaLihatSemua />}/>
            <Route path='/tanya-jaksa' element={<TanyaJaksa />} />
            <Route path='/tanya-jaksa/detail/:slug' element={<TanyaJaksaDetail />} />
            <Route path='/tanya-jaksa/kategori/:slug' element={<TanyaJaksaLihatSemua />} />
            <Route path='/event' element={<Event />} />
            <Route path='/event/detail/:slug' element={<EventDetail />} />
            <Route path='/lupa-katasandi' element={<LupaKataSandi />} />
            <Route path='hapus-akun' element={<HapusAkun />} />
            <Route path='/verifikasi-kode' element={<VerifikasiKode />} />
            <Route path='/reset-katasandi' element={<ResetKataSandi />}/>
            <Route path='/pencarian/:slug' element={<Pencarian />} />
            <Route path='/notifikasi' element={<Notifikasi />} />
            <Route path='*' element={<TidakDitemukan />} />
            <Route path='/helpdesk' element={<Helpdesk />} />
            <Route path='/helpdesk/:slug' element={<HelpdeskKategori />} />
            <Route path='/kebijakan-privasi' element={<KebijakanPrivasi />} />

            <Route element={<RequireAuth />} >
                <Route path='/dashboard/member' element={<ProfilMember />} />
                <Route path='/dashboard/member/profil' element={<ProfilMember />} />
                <Route path='/dashboard/member/riwayat-helpdesk' element={<RiwayatHelpdesk />} />
                <Route path='/dashboard/member/riwayat-helpdesk/detail/:id' element={<RiwayatHelpdesk />} />
                <Route path='/dashboard/member/pengaduan' element={<Pengaduan />} />
                <Route path='/dashboard/member/pengaduan/detail/:id' element={<Pengaduan />} />
                <Route path='/dashboard/member/kritik-saran' element={<KritikSaran />} />
                <Route path='/dashboard/member/kritik-saran/detail/:id' element={<KritikSaran />} />
                <Route path='/dashboard/member/tanya-jaksa' element={<MemberTanyaJaksa />} />
                <Route path='/dashboard/member/tanya-jaksa/detail/:slug' element={<MemberTanyaJaksa />} />
            </Route>

            <Route path='/admin' element={<Navigate to='/dashboard/admin' />} />
            <Route path='/admin/login' element={<LoginAdmin />} />
            <Route path='/admin/sso/callback' element={<LoginAdminSSOCallback />} />
            <Route path='/admin/sso/logout' element={<LogoutAdmin/>} />
            <Route element={<RequireAuthAdmin />} >
                <Route path='/dashboard/admin/profil' element={<ProfilAdmin />} />
                <Route path='/dashboard/admin/pencarian/:slug' element={<PencarianAdmin />} />
                <Route path='/dashboard/admin/notifikasi' element={<NotifikasiAdmin />} />
                <Route path='/dashboard/admin' element={<DashboardAdmin />} />
                <Route path='/dashboard/admin/dashboard' element={<DashboardAdmin />} />
                <Route path='/dashboard/admin/berita' element={<BeritaAdmin />} />
                <Route path='/dashboard/admin/berita/detail/:slug' element={<BeritaAdmin />} />
                <Route path='/dashboard/admin/berita/tambah' element={<BeritaAdminTambah />} />
                <Route path='/dashboard/admin/berita/ubah/:slug' element={<BeritaAdminUbah />} />
                <Route path='/dashboard/admin/kategori-berita' element={<KategoriBeritaAdmin />} />
                <Route path='/dashboard/admin/kategori-produk-hukum' element={<KategoriProdukHukumAdmin />} />
                <Route path='/dashboard/admin/hierarki-peraturan' element={<HierarkiPeraturanAdmin />} />
                <Route path='/dashboard/admin/produk-hukum' element={<ProdukHukumAdmin />} />
                <Route path='/dashboard/admin/produk-hukum/detail/:slug' element={<ProdukHukumAdmin />} />
                <Route path='/dashboard/admin/produk-hukum/tambah' element={<ProdukHukumAdminTambah />} />
                <Route path='/dashboard/admin/produk-hukum/ubah/:slug' element={<ProdukHukumAdminUbah />} />
                <Route path='/dashboard/admin/poling' element={<PolingAdmin />} />
                <Route path='/dashboard/admin/poling/detail/:id' element={<PolingAdmin />} />
                <Route path='/dashboard/admin/event' element={<EventAdmin />} />
                <Route path='/dashboard/admin/event/detail/:id' element={<EventAdmin />} />
                <Route path='/dashboard/admin/event/tambah' element={<EventAdminTambah />} />
                <Route path='/dashboard/admin/event/ubah/:slug' element={<EventAdminUbah />} />
                <Route path='/dashboard/admin/statistik' element={<StatistikAdmin />} />
                <Route path='/dashboard/admin/tanya-jaksa' element={<TanyaJaksaAdmin />} />
                <Route path='/dashboard/admin/tanya-jaksa/detail/:slug' element={<TanyaJaksaAdmin />} />
                <Route path='/dashboard/admin/tanya-jaksa/tambah' element={<TanyaJaksaAdminTambah />} />
                <Route path='/dashboard/admin/tanya-jaksa/tanggapan/:slug' element={<TanyaJaksaAdminTanggapan />} />
                <Route path='/dashboard/admin/tanya-jaksa/ubah/:slug' element={<TanyaJaksaAdminUbah />} />
                <Route path='/dashboard/admin/pengaduan-masyarakat' element={<PengaduanMasyarakatAdmin />} />
                <Route path='/dashboard/admin/pengaduan-masyarakat/detail/:id' element={<PengaduanMasyarakatAdmin />} />
                <Route path='/dashboard/admin/helpdesk' element={<HelpdeskAdmin />} />
                <Route path='/dashboard/admin/helpdesk/detail/:id' element={<HelpdeskAdmin />} />
                <Route path='/dashboard/admin/kategori-faq' element={<KategoriFaqAdmin />} />
                <Route path='/dashboard/admin/faq' element={<FaqAdmin />} />
                <Route path='/dashboard/admin/faq/tambah' element={<FaqAdminTambah />} />
                <Route path='/dashboard/admin/faq/ubah/:slug' element={<FaqAdminUbah />} />
                <Route path='/dashboard/admin/kritik-saran' element={<KritikSaranAdmin />} />
                <Route path='/dashboard/admin/kritik-saran/detail/:id' element={<KritikSaranAdmin />} />
                <Route path='/dashboard/admin/laporan' element={<LaporanAdmin />} />
                <Route path='/dashboard/admin/pengaturan/masyarakat' element={<MasyarakatAdmin />} />
                <Route path='/dashboard/admin/pengaturan/masyarakat/verifikasi/:id' element={<MasyarakatVerifikasiAdmin />} />

                <Route path='/dashboard/admin/instansi' element={<InstansiAdmin />} />
                <Route path='/dashboard/admin/kategori-laporan' element={<KategoriLaporanAdmin />} />

                <Route element={<RequireAuthTipeUser />} >
                    <Route path='/dashboard/admin/pengaturan/anggota' element={<AnggotaAdmin />} />
                    <Route path='/dashboard/admin/pengaturan/anggota/non-aktif' element={<AnggotaNonAktifAdmin />} />
                    <Route path='/dashboard/admin/pengaturan/pengaturan-umum' element={<PengaturanUmumAdmin />} />
                    <Route path='/dashboard/admin/pengaturan/log-aktivitas/anggota' element={<LogAktivitasAnggotaAdmin />} />
                    <Route path='/dashboard/admin/pengaturan/log-aktivitas/masyarakat' element={<LogAktivitasMasyarakatAdmin />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default AppRoutes