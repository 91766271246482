import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useNotifikasiStatus = (data) => {
    const [errorNotifikasiStatus, setErrorNotifikasiStatus] = useState('');
    const [successNotifikasiStatus, setSuccesNotifikasiStatus] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorNotifikasiStatus('');
                setSuccesNotifikasiStatus('');
                const API_URL = 'notifikasi/status';
                const fetchData = async () => {
                    await useAxios.patch(API_URL,
                        JSON.stringify({ status_baca: true }),
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setSuccesNotifikasiStatus(res?.data?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorNotifikasiStatus('API status notifikasi tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('user');
                            window.location.href = '/';
                        } else {
                            setErrorNotifikasiStatus(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorNotifikasiStatus, successNotifikasiStatus];
}

export default useNotifikasiStatus;