
import moment from 'moment';
import 'moment/locale/id';

export const formatTanggal = (x) => {
    return moment(x).format('LL');
}

export const formatWaktu = (x) => {
    return moment(x).format('H:mm:ss');
}

export const formatHari = (x) => {
    var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    var d = new Date(x);
    return days[d.getDay()];
}

export const formatBulan = (x) => {
    switch (x) {
        case '01': return 'Januari';
        case '02': return 'Februari';
        case '03': return 'Maret';
        case '04': return 'April';
        case '05': return 'Mei';
        case '06': return 'Juni';
        case '07': return 'Juli';
        case '08': return 'Agustus';
        case '09': return 'September';
        case '10': return 'Oktober';
        case '11': return 'November';
        case '12': return 'Desember';
        default: return '';
    }
}

export const formatTanggalKeDB = (x) => {
    return moment(x).format('YYYY-MM-DD');
}

export const formatDBKeTanggalwaktu = (x) => {
    return moment(x).format('LL H:mm:ss');
}

export const formatTanggalwaktuKeDB = (x) => {
    return moment(x).format('YYYY-MM-DD H:mm:ss');
}

export const waktuBerlalu = (x) => {
    return moment.utc(x).local().startOf('seconds').fromNow();
}

export const sisaWaktu = (x) => {
    return moment.utc(x).local().endOf('seconds').fromNow();
}

export const potongTextProdukHukumBeranda = (x) => {
    if (x.trim().length >= 190) {
        return x.substring(0, 190) + '...';
    } else {
        return x;
    }
}

export const potongText = (x) => {
    if (x.trim().length >= 50) {
        return x.substring(0, 50) + '...';
    } else {
        return x;
    }
}

export const potongTextJudul = (x) => {
    if (x.trim().length >= 77) {
        return x.substring(0, 77) + '...';
    } else {
        return x;
    }
}

export const potongTextStatistik = (x) => {
    if (x.trim().length >= 225) {
        return x.substring(0, 225) + '...';
    } else {
        return x;
    }
}

export const potongTextMenu = (x) => {
    if (x.trim().length >= 10) {
        return x.substring(0, 10) + '...';
    } else {
        return x;
    }
}

export const minTanggalLahir = (x) => {
    x.setFullYear(x.getFullYear() - 17);
    return x;
}

export const konversiSlug = (Text) => {
    return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
}

export const konversiSlugKeString = (Text) => {
    return Text.toLowerCase()
        .replace(/-/g, " ");
}

export const konversiTotal = (nominal) => {
    var hasil = 0;
    if (nominal >= 1000000000000) {
        hasil = nominal / 1000000000000;
    } else if (nominal >= 1000000000) {
        hasil = nominal / 1000000000;
    } else if (nominal >= 1000000) {
        hasil = nominal / 1000000;
    } else if (nominal >= 1000) {
        hasil = nominal / 1000;
    } else {
        hasil = nominal;
    }

    return hasil;
}

export const konversiTotalString = (nominal) => {
    var hasil = nominal;
    if (nominal >= 1000000000000) {
        hasil = 'T';
    } else if (nominal >= 1000000000) {
        hasil = 'M';
    } else if (nominal >= 1000000) {
        hasil = 'Jt';
    } else if (nominal >= 10000) {
        hasil = 'Rb';
    } else {
        hasil = '';
    }

    return hasil;
}