import { React, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalRegisterComponent from '../register/ModalRegister';
import useLogin from '../../../api/useLogin';
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import useLoginGoogle from '../../../api/useLoginGoogle';
import useLoginFacebook from '../../../api/useLoginFacebook';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalLogin = ({ showmodal, hidemodal }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errUser, setErrMessageUser] = useState('');
    const [errPwd, setErrMessagePwd] = useState('');
    const [postLogin, setPostLogin] = useState([]);
    const [errorLogin] = useLogin(postLogin);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [postLoginGoogle, setPostLoginGoogle] = useState('');
    const [errorLoginGoogle] = useLoginGoogle(postLoginGoogle);
    const [postLoginFacebook, setPostLoginFacebook] = useState('');
    const [errorLoginFacebook] = useLoginFacebook(postLoginFacebook);

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.trim().length === 0 && pwd.trim().length !== 0) {
            setErrMessageUser('Nama pengguna belum diisi.');
        }
        else if (user.trim().length !== 0 && pwd.trim().length === 0) {
            setErrMessagePwd('Katasandi belum diisi.');
        }
        else if (user.trim().length === 0 && pwd.trim.length === 0) {
            setErrMessageUser('Nama pengguna belum diisi.');
            setErrMessagePwd('Katasandi belum diisi.');
        }
        else {
            const logindata = {
                username: user,
                password: pwd,
                tipeuser: 'user'
            };
            setPostLogin(logindata);
        }
    }

    const clickModalRegister = (e) => {
        e.preventDefault();
        hidemodal();
        setShowModalRegister(true);
    }

    const hideModalRegister = () => {
        setShowModalRegister(false);
    }

    useEffect(() => {
        if (errorLogin !== '') {
            setErrorMessage(errorLogin);
        } else if (errorLoginGoogle !== '') {
            setErrorMessage(errorLoginGoogle);
        } else if (errorLoginFacebook !== '') {
            setErrorMessage(errorLoginFacebook);
        }
    }, [errorLogin, errorLoginGoogle, errorLoginFacebook]);

    return (
        <>
            <Modal
                aria-labelledby='modalLoginLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                show={showmodal}
                onHide={hidemodal}
            >
                <div className='col-sm-12 right-align'>
                    <button className='btn-close' type='button' onClick={hidemodal} style={{ 'all': 'unset', 'cursor': 'pointer' }}>
                        <LazyLoadImage src='/img/closemodal.svg' alt='close' />
                    </button>
                </div>
                <div className='modal-header modalheaderlogin'>
                    <div className='row center-align'>
                        <div className='col-xl-12 col-md-12 col-sm-12'>
                            <h4 className='modal-titlelogin'>Selamat Datang!</h4>
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12'>
                            <h5 className='submodal-titlelogin'>Berapa langkah lagi untuk memulai.</h5>
                        </div>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                        {errorMessage}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <div className={errUser ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                <input
                                    className='form-control form-control-lg formcustom formappend'
                                    id='username_login'
                                    type='text'
                                    name='username'
                                    placeholder='Nama Pengguna'
                                    autoComplete='off'
                                    autoFocus
                                    onChange={(e) => setUser(e.target.value)}
                                    onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                    value={user}
                                />
                                <div className='input-group-append'>
                                    <span className='input-group-text spanformcustom'>
                                        <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                    </span>
                                </div>
                            </div>
                            <div className='errorform'>{errUser}</div>
                        </div>
                        <div className='form-group'>
                            <div className={errPwd ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                <input
                                    className='form-control form-control-lg formcustom formappend'
                                    id='password_login'
                                    type={passwordType}
                                    name='password'
                                    placeholder='Katasandi'
                                    onChange={(e) => setPwd(e.target.value)}
                                    onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                    value={pwd}
                                />
                                <div className='input-group-append'>
                                    <span className='input-group-text spanformcustom' onClick={togglePassword} style={{ 'cursor': 'pointer' }}>
                                        <LazyLoadImage src='/img/sc-eye.svg' alt='logout' />
                                    </span>
                                </div>
                            </div>
                            <div className='errorform'>{errPwd}</div>
                        </div>
                        <div className='col-12 text-right'>
                            <a href='/lupa-katasandi' className='btn btn-link px-0 buttonlupa'>Lupa katasandi?</a>
                        </div>
                        <div className='col-12 center-align'>
                            <button className='btn btn-lg btn-primary btnmasuklogin' type='submit' onClick={handleSubmit}>Masuk</button>
                        </div>
                    </form>
                    <div className='col-12 center-align'>
                        <p className='belumpunyaakun'>Atau masuk dengan :</p>
                    </div>
                    <div className='col-12 center-align'>
                        <LoginSocialGoogle
                            isOnlyGetToken
                            client_id={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}
                            scope="openid profile email"
                            onResolve={({ data }) => {
                                setPostLoginGoogle(data?.access_token)
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='btn btn-lg btn-light btnsosialmedia' type='button'>
                                <LazyLoadImage src='/img/google.svg' alt='google' /> Google
                            </button>
                        </LoginSocialGoogle>
                    </div>
                    <div className='col-12 center-align' style={{ 'marginTop': '-30px' }}>
                        <LoginSocialFacebook
                            isOnlyGetToken
                            appId={process.env.REACT_APP_FACEBOOK_OAUTH_APP_ID || ''}
                            fieldsProfile={
                              'id,name,email'
                            }
                            onResolve={({ data }) => {
                                setPostLoginFacebook(data?.accessToken);
                            }}
                            onReject={(err) => {
                                console.log(err)
                            }}
                        >
                            <button className='btn btn-lg btn-light btnsosialmedia' type='button'>
                                <LazyLoadImage src='/img/facebook.svg' alt='facebook' /> Facebook
                            </button>
                        </LoginSocialFacebook>
                    </div>
                    <div className='col-12 center-align'>
                        <p className='belumpunyaakun'>Belum punya akun?</p>
                    </div>
                    <div className='col-12 center-align'>
                        <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={clickModalRegister}>Daftar Sekarang</button>
                    </div>
                </div>
            </Modal >
            <ModalRegisterComponent
                showmodal={showModalRegister}
                hidemodal={hideModalRegister}
            />
        </>
    )
}

export default ModalLogin