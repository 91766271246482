import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";

const usePolingDetail = (data) => {
    const [polingDetailData, setPolingDetailData] = useState([]);
    const [errorPolingDetail, setErrorPolingDetail] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorPolingDetail('');
                const API_URL = 'poling/detail/' + data?.id;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        setPolingDetailData(res.data.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorPolingDetail('API detail poling tidak ada response dari server');
                        } else {
                            setErrorPolingDetail(err.response.data.data);
                        }
                    });
                };
                fetchData();
            }
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [polingDetailData, errorPolingDetail];
}

export default usePolingDetail;