import { React, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalSuksesComponent from '../sukses/ModalSukses';
import useKataSandiEdit from '../../../api/useKataSandiEdit';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalEditKataSandi = ({ showmodal, hidemodal }) => {
    const auth = JSON.parse(localStorage.getItem('user'));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordlamaType, setPasswordLamaType] = useState('password');
    const [passwordType, setPasswordType] = useState('password');
    const [konfirmasiPasswordType, setKonfirmasiPasswordType] = useState('password');
    const [showModalSukses, setShowModalSukses] = useState(false);
    const [user, setUser] = useState(auth?.data?.username);
    const [pwdlama, setPwdLama] = useState('');
    const [pwd, setPwd] = useState('');
    const [konfirmasi, setKonfirmasi] = useState('');
    const [errUser, setErrMessageUser] = useState('');
    const [errPwdLama, setErrMessagePwdLama] = useState('');
    const [errPwd, setErrMessagePwd] = useState('');
    const [errKonfirmasi, setErrMessageKonfirmasi] = useState('');
    const [postKataSandi, setPostKataSandi] = useState([]);
    const [errorKataSandiEdit, successKataSandiEdit] = useKataSandiEdit(postKataSandi);
    const [countSukses, setCountSukses] = useState(0);
    const [showModalEdit, setShowModalEdit] = useState(false);

    useEffect(() => {
        setShowModalEdit(showmodal);
        if (showmodal === true) {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            setErrMessageUser('');
            setErrMessagePwdLama('');
            setErrMessagePwd('');
            setErrMessageKonfirmasi('');
        }
    }, [showmodal]);

    const togglePasswordLama = () => {
        if (passwordlamaType === 'password') {
            setPasswordLamaType('text');
            return;
        }
        setPasswordLamaType('password');
    }

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
            return;
        }
        setPasswordType('password');
    }

    const toggleKonfirmasiPassword = () => {
        if (konfirmasiPasswordType === 'password') {
            setKonfirmasiPasswordType('text');
            return;
        }
        setKonfirmasiPasswordType('password');
    }

    const tutupModal = () => {
        setShowModalSukses(false);
        hidemodal();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCountSukses(0);
        setErrorMessage('');
        setSuccessMessage('');
        if (user.trim().length === 0) {
            setErrMessageUser('Nama pengguna belum diisi.');
        }
        else if (pwdlama.trim().length === 0) {
            setErrMessagePwdLama('Katasandi Lama belum diisi.');
        }
        else if (pwd.trim().length === 0) {
            setErrMessagePwd('Katasandi Baru belum diisi.');
        }
        else if (konfirmasi.trim().length === 0) {
            setErrMessageKonfirmasi('Ulangi Katasandi Baru belum diisi.');
        }
        else if (pwd !== konfirmasi) {
            setErrMessageKonfirmasi('Ulangi Katasandi Baru tidak cocok dengan Katasandi Baru.');
        }
        else {
            const katasandidata = {
                username: user,
                password: pwd,
                password_lama: pwdlama,
                token: auth?.data?.token,
            }
            setPostKataSandi(katasandidata);
        }
    }

    useEffect(() => {
        if (errorKataSandiEdit !== '') {
            setErrorMessage(errorKataSandiEdit);
            setCountSukses(0);
        }
    }, [errorKataSandiEdit]);

    useEffect(() => {
        if (successKataSandiEdit !== '') {
            setSuccessMessage(successKataSandiEdit);
            setCountSukses(1);
        }
    }, [successKataSandiEdit]);

    useEffect(() => {
        if (countSukses === 1 && errorMessage === '' && successMessage !== '') {
            hidemodal();
            setShowModalSukses(true);
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            setErrMessageUser('');
            setErrMessagePwdLama('');
            setErrMessagePwd('');
            setErrMessageKonfirmasi('');
        }
    }, [hidemodal, countSukses, errorMessage, successMessage]);

    return (
        <>
            <Modal
                aria-labelledby='modalDetailLabel'
                role='dialog'
                tabIndex='-1'
                centered
                onHide={hidemodal}
                show={showModalEdit}
            >
                <div className='modal-header modalheaderprofil'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titleprofil'>Ubah Katasandi</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                        {errorMessage}
                    </div>
                    <div className='form-group'>
                        <div className={errUser ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='username_edit_katasandi'
                                type='text'
                                name='username'
                                placeholder='Nama Pengguna'
                                autoComplete='off'
                                onChange={(e) => setUser(e.target.value)}
                                onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                value={user}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom'>
                                    <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                </span>
                            </div>
                        </div>
                        <div className='errorform'>{errUser}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errPwdLama ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='passwordlama_login'
                                type={passwordlamaType}
                                name='passwordlama'
                                placeholder='Katasandi Lama'
                                onChange={(e) => setPwdLama(e.target.value)}
                                onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                value={pwdlama}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom' onClick={togglePasswordLama} style={{ 'cursor': 'pointer' }}>
                                    <LazyLoadImage src='/img/sc-eye.svg' alt='logout' />
                                </span>
                            </div>
                        </div>
                        <div className='errorform'>{errPwdLama}</div>
                        <div className='infoform'>Jika anda login menggunakan google / facebook dan belum pernah mengganti password, silahkan isi dengan 0</div>
                    </div>
                    <hr />
                    <div className='forminputlogin'>
                        <div className={errPwd ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='password_lupakatasandi'
                                type={passwordType}
                                name='password'
                                placeholder='Katasandi Baru'
                                autoComplete='off'
                                onChange={(e) => setPwd(e.target.value)}
                                onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                value={pwd}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom' onClick={togglePassword} style={{ 'cursor': 'pointer' }}>
                                    <LazyLoadImage src='/img/sc-eye.svg' alt='resetkatasandi' />
                                </span>
                            </div>
                        </div>
                        <div className='errorformlogin'>{errPwd}</div>
                    </div>
                    <div className='forminputlogin'>
                        <div className={errKonfirmasi ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='konfirmasi_lupakatasandi'
                                type={konfirmasiPasswordType}
                                name='konfirmasi'
                                placeholder='Ulangi Katasandi Baru'
                                onChange={(e) => setKonfirmasi(e.target.value)}
                                onKeyDown={(e) => {if(e.key === 'Enter') handleSubmit(e);}}
                                value={konfirmasi}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom' onClick={toggleKonfirmasiPassword} style={{ 'cursor': 'pointer' }}>
                                    <LazyLoadImage src='/img/sc-eye.svg' alt='resetkatasandi' />
                                </span>
                            </div>
                        </div>
                        <div className='errorformlogin'>{errKonfirmasi}</div>
                    </div>
                    <div className='center-align'>
                        <button type='button' onClick={handleSubmit} className='btn btn-lg btn-tambahkan' style={{ 'width': '100%' }} disabled={!user}>Lanjutkan</button>
                    </div>
                </div>
            </Modal>
            <ModalSuksesComponent
                showmodal={showModalSukses}
                hidemodal={tutupModal}
                textsukses='Katasandi kamu berhasil diubah.'
            />
        </>
    )
}

export default ModalEditKataSandi