import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useNotifikasi = (data) => {
    const [notifikasiData, setNotifikasiData] = useState([]);
    const [totalNotifikasiData, setTotalNotifikasiData] = useState(0);
    const [loadingNotifikasi, setLoadingNotifikasi] = useState(true);
    const [errorNotifikasi, setErrorNotifikasi] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorNotifikasi('');
                const API_URL = 'notifikasi?offset=' + data?.offset + '&limit=' + data?.limit + '&tipe=' + data?.tipe + '&cari=' + data?.cari;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setNotifikasiData(res.data.data);
                        setTotalNotifikasiData(res.data.total_data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorNotifikasi('API notifikasi tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            if (data?.tipe === 'masyarakat') {
                                localStorage.removeItem('user');
                                window.location.href = '/';
                            } else if (data?.tipe === 'admin') {
                                localStorage.removeItem('admin');
                                window.location.href = '/admin/login';
                            }
                        } else {
                            setErrorNotifikasi(err.response.data.data);
                        }
                    });
                };
                setLoadingNotifikasi(false);
                fetchData();
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [data]);

    return [notifikasiData, totalNotifikasiData, loadingNotifikasi, errorNotifikasi];
}

export default useNotifikasi;