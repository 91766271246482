import { React, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import useInstansi from '../../../api/useInstansi';
import useKotaKabupaten from '../../../api/useKotaKabupaten';
import usePengaduanMasyarakatTambah from '../../../api/usePengaduanMasyarakatTambah';
import useKritikSaranTambah from '../../../api/useKritikSaranTambah';
import ModalSuksesLaporComponent from '../sukses/ModalSuksesLapor';
import useTanyaJaksaTambah from '../../../api/useTanyaJaksaTambah';
import usePengaduanMasyarakatUploadFile from '../../../api/usePengaduanMasyarakatUploadFile';
import useKategoriLaporanSub from '../../../api/useKategoriLaporanSub';
import { formatTanggalKeDB } from '../../../utils/Utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import id from 'date-fns/locale/id';
import { getYear, getMonth } from 'date-fns';
import range from 'lodash/range';
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalLapor = ({ showmodal, hidemodal }) => {
    const years = range(1945, getYear(new Date()) + 1, 1);
    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];
    const [showModalSuksesLapor, setShowModalSuksesLapor] = useState(false);
    const [textSukses, setTextSukses] = useState('');
    const [linkButton, setLinkButton] = useState('');
    const [textButton, setTextButton] = useState('');

    const [countSuksesKritikSaran, setCountSuksesKritikSaran] = useState(0);
    const [countSuksesPermintaanInformasi, setCountSuksesPermintaanInformasi] = useState(0);

    //Pengaduan Masyarakat
    const [countSuksesPengaduanMasyarakat, setCountSuksesPengaduanMasyarakat] = useState(0);
    const [errJudulPengaduan, setErrJudulPengaduan] = useState('');
    const [judulPengaduan, setJudulPengaduan] = useState('');
    const [errKontenPengaduan, setErrKontenPengaduan] = useState('');
    const [kontenPengaduan, setKontenPengaduan] = useState('');
    const [errTanggalPengaduan, setErrTanggalPengaduan] = useState('');
    const [tanggalPengaduan, setTanggalPengaduan] = useState('');

    const [instansiPengaduan, setInstansiPengaduan] = useState(null);
    const [instansipengaduandata, setinstansipengaduandata] = useState([]);
    const [instansiPengaduanData, , , errorInstansiPengaduan] = useInstansi(instansipengaduandata);

    const [kotaKabupatenPengaduan, setKotaKabupatenPengaduan] = useState(null);
    const [errKotaKabupatenPengaduan, setErrKotaKabupatenPengaduan] = useState('');
    const [kotakabupatenpengaduandata, setkotakabupatenpengaduandata] = useState([]);
    const [kotaKabupatenPengaduanData, , , errorKotaKabupatenPengaduanData] = useKotaKabupaten(kotakabupatenpengaduandata);

    const [kategoriLaporanSubPengaduan, setKategoriLaporanSubPengaduan] = useState(null);
    const [kategorilaporansubpengaduandata, setkategorilaporansubpengaduandata] = useState([]);
    const [kategoriLaporanSubPengaduanData, , , errorKategoriLaporanSubPengaduanData] = useKategoriLaporanSub(kategorilaporansubpengaduandata);

    const auth = JSON.parse(localStorage.getItem('user'));
    const [errorMessage, setErrorMessage] = useState('');
    const [postPengaduan, setPengaduan] = useState([]);
    const [idPengaduanMasyarakat, errorPengaduanMasyarakatTambah] = usePengaduanMasyarakatTambah(postPengaduan);

    const [previewFilePengaduanMasyarakat, setPreviewFilePengaduanMasyarakat] = useState('/img/uploadimage.svg');
    const [filePengaduanMasyarakat, setFilePengaduanMasyarakat] = useState(null);
    const [tipeFilePengaduanMasyarakat, setTipeFilePengaduanMasyarakat] = useState('');
    const [ukuranFilePengaduanMasyarakat, setUkuranFilePengaduanMasyarakat] = useState('');
    const handleUploadFile = (e) => {
        if (e.target.files[0] !== undefined) {
            if (e.target.files[0].type === 'image/jpeg' && e.target.files[0].type === 'image/png' && e.target.files[0].type === 'image/jpg' && e.target.files[0].type === 'image/gif' && e.target.files[0].type === 'image/svg') {
                setPreviewFilePengaduanMasyarakat(URL.createObjectURL(e.target.files[0]));
            } else {
                setPreviewFilePengaduanMasyarakat('/img/default-file.svg');
            }
        }
        setFilePengaduanMasyarakat(e.target.files[0]);
        setTipeFilePengaduanMasyarakat(e.target.files[0].type);
        setUkuranFilePengaduanMasyarakat(e.target.files[0].size);
    }
    const [postPengaduanMasyarakatUploadFileData, setPostPengaduanMasyarakatUploadFileData] = useState([]);
    const [errorPengaduanMasyarakatUploadFile, successPengaduanMasyarakatUploadFile] = usePengaduanMasyarakatUploadFile(postPengaduanMasyarakatUploadFileData);
    const [errorFilePengaduanMasyarakat, setErrorFilePengaduanMasyarakat] = useState('');

    const kirimPengaduan = async (e) => {
        e.preventDefault();
        if (judulPengaduan.trim().length === 0) {
            setErrJudulPengaduan('Judul Laporan belum diisi.');
        }
        else if (kontenPengaduan.trim().length === 0) {
            setErrKontenPengaduan('Isi Laporan Anda belum diisi.');
        }
        else if (filePengaduanMasyarakat === null) {
            setErrorFilePengaduanMasyarakat('File belum diisi.');
        }
        else if (tanggalPengaduan === '') {
            setErrTanggalPengaduan('Tanggal Kejadian belum dipilih');
        }
        else if (kotaKabupatenPengaduan === null) {
            setErrKotaKabupatenPengaduan('Lokasi Kejadian belum dipilih.');
        }
        else {
            const pengaduandata = {
                instansis_id: instansiPengaduan,
                kota_kabupatens_id: kotaKabupatenPengaduan,
                kategori_laporan_subs_id: kategoriLaporanSubPengaduan,
                tanggal: formatTanggalKeDB(tanggalPengaduan),
                judul: judulPengaduan,
                konten: kontenPengaduan,
                token: auth?.data?.token
            }
            setPengaduan(pengaduandata);
        }
    }

    useEffect(() => {
        if (filePengaduanMasyarakat !== null && idPengaduanMasyarakat !== 0) {
            const auth = JSON.parse(localStorage.getItem('user'));
            const pengaduanmasyarakatuploadfiledata = {
                id: idPengaduanMasyarakat,
                file: filePengaduanMasyarakat,
                tipefile: tipeFilePengaduanMasyarakat,
                ukuranfile: ukuranFilePengaduanMasyarakat,
                token: auth?.data?.token,
                tipe: 'tambah',
            }
            setPostPengaduanMasyarakatUploadFileData(pengaduanmasyarakatuploadfiledata);
            setCountSuksesPengaduanMasyarakat(1);
        }
    }, [idPengaduanMasyarakat, filePengaduanMasyarakat, tipeFilePengaduanMasyarakat, ukuranFilePengaduanMasyarakat, judulPengaduan]);
    //Pengaduan Masyarakat

    //Kritik Saran
    const [errJudulKritikSaran, setErrJudulKritikSaran] = useState('');
    const [judulKritikSaran, setJudulKritikSaran] = useState('');
    const [errKontenKritikSaran, setErrKontenKritikSaran] = useState('');
    const [kontenKritikSaran, setKontenKritikSaran] = useState('');

    const [instansiKritikSaran, setInstansiKritikSaran] = useState(null);
    const [instansikritiksarandata, setinstansikritiksarandata] = useState([]);
    const [instansiKritikSaranData, , , errorInstansiKritikSaran] = useInstansi(instansikritiksarandata);

    const [kotaKabupatenKritikSaran, setKotaKabupatenKritikSaran] = useState(null);
    const [errKotaKabupatenKritikSaran, setErrKotaKabupatenKritikSaran] = useState('');
    const [kotakabupatenkritiksarandata, setkotakabupatenkritiksarandata] = useState([]);
    const [kotaKabupatenKritikSaranData, , , errorKotaKabupatenKritikSaranData] = useKotaKabupaten(kotakabupatenkritiksarandata);

    const [kategoriLaporanSubKritikSaran, setKategoriLaporanSubKritikSaran] = useState(null);
    const [kategorilaporansubkritiksarandata, setkategorilaporansubkritiksarandata] = useState([]);
    const [kategoriLaporanSubKritikSaranData, , , errorKategoriLaporanSubKritikSaranData] = useKategoriLaporanSub(kategorilaporansubkritiksarandata);

    const [postKritikSaran, setKritikSaran] = useState([]);
    const [errorKritikSaranTambah, successKritikSaranTambah] = useKritikSaranTambah(postKritikSaran);

    const kirimKritikSaran = async (e) => {
        e.preventDefault();
        if (judulKritikSaran.trim().length === 0) {
            setErrJudulKritikSaran('Judul Kritik Saran belum diisi.');
        }
        else if (kontenKritikSaran.trim().length === 0) {
            setErrKontenKritikSaran('Isi Kritik Saran belum diisi.');
        }
        else if (kotaKabupatenKritikSaran === null) {
            setErrKotaKabupatenKritikSaran('Asal Pelapor belum dipilih.');
        }
        else {
            const kritiksarandata = {
                user_tanggapans_id: 0,
                instansis_id: instansiKritikSaran,
                kota_kabupatens_id: kotaKabupatenKritikSaran,
                kategori_laporan_subs_id: kategoriLaporanSubKritikSaran,
                judul: judulKritikSaran,
                konten: kontenKritikSaran,
                tanggapan: null,
                token: auth?.data?.token
            }
            setKritikSaran(kritiksarandata);
            setCountSuksesKritikSaran(1);
        }
    }
    //Kritik Saran

    //Permintaan Informasi
    const [errJudulPermintaanInformasi, setErrJudulPermintaanInformasi] = useState('');
    const [judulPermintaanInformasi, setJudulPermintaanInformasi] = useState('');
    const [errKontenPermintaanInformasi, setErrKontenPermintaanInformasi] = useState('');
    const [kontenPermintaanInformasi, setKontenPermintaanInformasi] = useState('');

    const [instansiPermintaanInformasi, setInstansiPermintaanInformasi] = useState(null);
    const [instansipermintaaninformasidata, setinstansipermintaaninformasidata] = useState([]);
    const [instansiPermintaanInformasiData, , , errorInstansiPermintaanInformasi] = useInstansi(instansipermintaaninformasidata);

    const [kotaKabupatenPermintaanInformasi, setKotaKabupatenPermintaanInformasi] = useState(null);
    const [errKotaKabupatenPermintaanInformasi, setErrKotaKabupatenPermintaanInformasi] = useState('');
    const [kotakabupatenpermintaaninformasidata, setkotakabupatenpermintaaninformasidata] = useState([]);
    const [kotaKabupatenPermintaanInformasiData, , , errorKotaKabupatenPermintaanInformasiData] = useKotaKabupaten(kotakabupatenpermintaaninformasidata);

    const [kategoriLaporanSubPermintaanInformasi, setKategoriLaporanSubPermintaanInformasi] = useState(null);
    const [kategorilaporansubpermintaaninformasidata, setkategorilaporansubpermintaaninformasidata] = useState([]);
    const [kategoriLaporanSubPermintaanInformasiData, , , errorKategoriLaporanSubPermintaanInformasiData] = useKategoriLaporanSub(kategorilaporansubpermintaaninformasidata);

    const [postPermintaanInformasi, setPermintaanInformasi] = useState([]);
    const [, errorPermintaanInformasi, successPermintaanInformasi] = useTanyaJaksaTambah(postPermintaanInformasi);

    const kirimPermintaanInformasi = async (e) => {
        e.preventDefault();
        if (judulPermintaanInformasi.trim().length === 0) {
            setErrJudulPermintaanInformasi('Judul Permintaan Informasi belum diisi.');
        }
        else if (kontenPermintaanInformasi.trim().length === 0) {
            setErrKontenPermintaanInformasi('Isi Permintaan Informasi belum diisi.');
        }
        else if (kotaKabupatenPermintaanInformasi === null) {
            setErrKotaKabupatenPermintaanInformasi('Asal Pelapor belum dipilih.');
        }
        else {
            const permintaaninformasidata = {
                instansis_id: instansiPermintaanInformasi,
                kota_kabupatens_id: kotaKabupatenPermintaanInformasi,
                kategori_laporan_subs_id: kategoriLaporanSubPermintaanInformasi,
                judul: judulPermintaanInformasi,
                konten: kontenPermintaanInformasi,
                token: auth?.data?.token,
                tipe: 'lapor',
            }
            setPermintaanInformasi(permintaaninformasidata);
            setCountSuksesPermintaanInformasi(1);
        }
    }

    const hideModalSuksesLapor = () => {
        setShowModalSuksesLapor(false);
    }
    //Permintaan Informasi

    useEffect(() => {
        if (showmodal === true) {
            const getinstasidata = {
                offset: 0,
                limit: 0,
                cari: '',
            }
            setinstansipengaduandata(getinstasidata);
            setinstansikritiksarandata(getinstasidata);
            setinstansipermintaaninformasidata(getinstasidata);
    
            const getkotakabupatendata = {
                offset: 0,
                limit: 0,
                cari: '',
            }
            setkotakabupatenpengaduandata(getkotakabupatendata);
            setkotakabupatenkritiksarandata(getkotakabupatendata);
            setkotakabupatenpermintaaninformasidata(getkotakabupatendata);
    
            const getkategorilaporansubdata = {
                id: 0,
                offset: 0,
                limit: 0,
                cari: '',
            }
            setkategorilaporansubpengaduandata(getkategorilaporansubdata);
            setkategorilaporansubkritiksarandata(getkategorilaporansubdata);
            setkategorilaporansubpermintaaninformasidata(getkategorilaporansubdata);


            setCountSuksesPengaduanMasyarakat(0);
            setJudulPengaduan('');
            setKontenPengaduan('');
            setPreviewFilePengaduanMasyarakat('/img/uploadimage.svg');
            setErrorMessage('');
            setErrJudulPengaduan('');
            setErrorFilePengaduanMasyarakat('');

            setCountSuksesKritikSaran(0);
            setJudulKritikSaran('');
            setKontenKritikSaran('');
            setErrorMessage('');
            setErrJudulKritikSaran('');
            setErrKontenKritikSaran('');

            setCountSuksesPermintaanInformasi(0);
            setJudulPermintaanInformasi('');
            setKontenPermintaanInformasi('');
            setErrorMessage('');
            setErrJudulPermintaanInformasi('');
            setErrKontenPermintaanInformasi('');
        }
    }, [showmodal]);

    useEffect(() => {
        if (errorPengaduanMasyarakatTambah !== '') {
            setErrorMessage(errorPengaduanMasyarakatTambah);
        } else if (errorKritikSaranTambah !== '') {
            setErrorMessage(errorKritikSaranTambah);
        } else if (errorPermintaanInformasi !== '') {
            setErrorMessage(errorPermintaanInformasi);
        } else if (errorPengaduanMasyarakatUploadFile !== '') {
            setErrorMessage(errorPengaduanMasyarakatUploadFile);
        } else if (errorInstansiPengaduan !== '') {
            setErrorMessage(errorInstansiPengaduan);
        } else if (errorKotaKabupatenPengaduanData !== '') {
            setErrorMessage(errorKotaKabupatenPengaduanData);
        } else if (errorKategoriLaporanSubPengaduanData !== '') {
            setErrorMessage(errorKategoriLaporanSubPengaduanData);
        } else if (errorInstansiKritikSaran !== '') {
            setErrorMessage(errorInstansiKritikSaran);
        } else if (errorKotaKabupatenKritikSaranData !== '') {
            setErrorMessage(errorKotaKabupatenKritikSaranData);
        } else if (errorKategoriLaporanSubKritikSaranData !== '') {
            setErrorMessage(errorKategoriLaporanSubKritikSaranData);
        } else if (errorInstansiPermintaanInformasi !== '') {
            setErrorMessage(errorInstansiPermintaanInformasi);
        } else if (errorKotaKabupatenPermintaanInformasiData !== '') {
            setErrorMessage(errorKotaKabupatenPermintaanInformasiData);
        } else if (errorKategoriLaporanSubPermintaanInformasiData !== '') {
            setErrorMessage(errorKategoriLaporanSubPermintaanInformasiData);
        }
    }, [errorPengaduanMasyarakatTambah, errorKritikSaranTambah, errorPermintaanInformasi, errorPengaduanMasyarakatUploadFile, errorInstansiPengaduan, errorKotaKabupatenPengaduanData, errorKategoriLaporanSubPengaduanData, errorInstansiKritikSaran, errorKotaKabupatenKritikSaranData, errorKategoriLaporanSubKritikSaranData, errorInstansiPermintaanInformasi, errorKotaKabupatenPermintaanInformasiData, errorKategoriLaporanSubPermintaanInformasiData]);

    useEffect(() => {
        if (countSuksesPengaduanMasyarakat === 1 && errorPengaduanMasyarakatUploadFile === '' && successPengaduanMasyarakatUploadFile !== '') {
            hidemodal();
            setTextSukses('Pengaduan kamu berhasil dikirimkan. Cek halaman <b>Pengaduan</b> di <b>Profil</b> kamu untuk melihat respon');
            setLinkButton('/dashboard/member/pengaduan');
            setTextButton('Pengaduan');
            setShowModalSuksesLapor(true);
            setCountSuksesPengaduanMasyarakat(0);
            setJudulPengaduan('');
            setKontenPengaduan('');
            setPreviewFilePengaduanMasyarakat('/img/uploadimage.svg');
            setErrorMessage('');
            setErrJudulPengaduan('');
            setErrorFilePengaduanMasyarakat('');
        } else if (countSuksesKritikSaran === 1 && errorKritikSaranTambah === '' && successKritikSaranTambah !== '') {
            hidemodal();
            setTextSukses('Kritik & saran kamu berhasil dikirimkan. Cek halaman <b>Kritik & Saran</b> di <b>Profil</b> kamu untuk melihat respon.')
            setLinkButton('/dashboard/member/kritik-saran');
            setTextButton('Kritik & Saran')
            setShowModalSuksesLapor(true);
            setCountSuksesKritikSaran(0);
            setJudulKritikSaran('');
            setKontenKritikSaran('');
            setErrorMessage('');
            setErrJudulKritikSaran('');
            setErrKontenKritikSaran('');
        } else if (countSuksesPermintaanInformasi === 1 && errorPermintaanInformasi === '' && successPermintaanInformasi !== '') {
            hidemodal();
            setTextSukses('Permintaan informasi kamu berhasil dikirimkan. Cek halaman <b>Tanya Jaksa</b> di <b>Profil</b> kamu untuk melihat respon.')
            setLinkButton('/dashboard/member/tanya-jaksa');
            setTextButton('Permintaan Inf...')
            setShowModalSuksesLapor(true);
            setCountSuksesPermintaanInformasi(0);
            setJudulPermintaanInformasi('');
            setKontenPermintaanInformasi('');
            setErrorMessage('');
            setErrJudulPermintaanInformasi('');
            setErrKontenPermintaanInformasi('');
        }
    }, [hidemodal, countSuksesPengaduanMasyarakat, errorPengaduanMasyarakatUploadFile, successPengaduanMasyarakatUploadFile, countSuksesKritikSaran, errorKritikSaranTambah, successKritikSaranTambah, countSuksesPermintaanInformasi, errorPermintaanInformasi, successPermintaanInformasi]);

    return (
        <>
            <Modal
                aria-labelledby='modalLaporLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                dialogClassName="modal-lg"
                show={showmodal}
                onHide={hidemodal}
            >
                <div className='modal-header modalheaderprofil'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titlelaporan'>Sampaikan Laporan Anda!</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                        {errorMessage}
                    </div>
                    <div className='nav nav-tabs-boxed'>
                        <ul className='nav nav-tabs navtabkategori mb-3' role='tablist'>
                            <li className='nav-item'><a className='nav-link active' data-toggle='tab' href='#pengaduan' role='tab' aria-controls='pengaduan'>Pengaduan</a></li>
                            <li className='nav-item'><a className='nav-link' data-toggle='tab' href='#kritiksaran' role='tab' aria-controls='kritiksaran'>Kritik & Saran</a></li>
                            {/* <li className='nav-item'><a className='nav-link' data-toggle='tab' href='#permintaaninformasi' role='tab' aria-controls='permintaaninformasi'>Permintaan Informasi</a></li> */}
                        </ul>
                        <div className='col-sm-12'>
                            <div className='tab-content no-border'>
                                <div className='tab-pane active' id='pengaduan' role='tabpanel'>
                                    <div className='form-group'>
                                        <div className={errJudulPengaduan ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <input
                                                className='form-control form-control-lg formcustom'
                                                id='judul_pengaduan'
                                                type='text'
                                                name='judul_pengaduan'
                                                placeholder='Judul Laporan'
                                                onChange={(e) => setJudulPengaduan(e.target.value)}
                                                onKeyDown={(e) => {if(e.key === 'Enter') kirimPengaduan(e);}}
                                                value={judulPengaduan}
                                            />
                                        </div>
                                        <div className='errorform left-align'>{errJudulPengaduan}</div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='left-align'>
                                            <label className={errorFilePengaduanMasyarakat ? 'labeluploadcustom is-errorform' : 'labeluploadcustom'} htmlFor='file_pengaduan'>
                                                <input
                                                    id='file_pengaduan'
                                                    type='file'
                                                    className='uploadfilecustom'
                                                    onChange={handleUploadFile}
                                                    accept='.png, .jpeg, .jpg, .gif, .svg, .pdf, .doc'
                                                />
                                                <LazyLoadImage className='previewimageuploadlapor' src={previewFilePengaduanMasyarakat} alt='upload' />
                                            </label>
                                        </div>
                                        <p className='informasiuploadimagelaporan left-align'>Tambahkan berkas berupa gambar .jpg, .png, dan/atau dokumen .doc, .pdf</p>
                                        <div className='errorform mb-2 left-align'>{errorFilePengaduanMasyarakat}</div>
                                    </div>
                                    <div className='form-group'>
                                        <div className={errKontenPengaduan ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <textarea
                                                className='form-control form-control-lg formcustom'
                                                id='konten_pengaduan'
                                                type='text'
                                                rows='5'
                                                name='konten_pengaduan'
                                                placeholder='Isi Laporan Anda'
                                                onChange={(e) => setKontenPengaduan(e.target.value)}
                                                value={kontenPengaduan}
                                            />
                                            <p className='countingtextarea'>{kontenPengaduan.length} / 250</p>
                                        </div>
                                        <div className='errorform left-align'>{errKontenPengaduan}</div>
                                    </div>
                                    <div className='form-group'>
                                        <div className='input-group inputgroupmodalcustom'>
                                            <DatePicker
                                                renderCustomHeader={({
                                                    date,
                                                    changeYear,
                                                    changeMonth,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled,
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <button type='button' className='btn btn-primary btnarrowdatepicker arrowdatepickerleft' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {'<'}
                                                        </button>
                                                        <select
                                                            className='form-control form-control-lg formcustom selectdatepicker'
                                                            value={getYear(date)}
                                                            onChange={({ target: { value } }) => changeYear(value)}
                                                        >
                                                            {years.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <select
                                                            className='form-control form-control-lg formcustom selectdatepicker'
                                                            value={months[getMonth(date)]}
                                                            onChange={({ target: { value } }) =>
                                                                changeMonth(months.indexOf(value))
                                                            }
                                                        >
                                                            {months.map((option) => (
                                                                <option key={option} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <button type='button' className='btn btn-primary btnarrowdatepicker arrowdatepickerright' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {'>'}
                                                        </button>
                                                    </div>
                                                )}
                                                className={errTanggalPengaduan ? 'form-control form-control-lg formcustom is-errorform' : 'form-control form-control-lg formcustom'}
                                                dateFormat='dd MMMM yyyy'
                                                selected={tanggalPengaduan}
                                                locale={id}
                                                maxDate={new Date()}
                                                placeholderText='Pilih Tanggal Kejadian'
                                                closeOnScroll={true}
                                                onChange={(tanggalPengaduan) => setTanggalPengaduan(tanggalPengaduan)} />
                                        </div>
                                        <div className='errorform left-align'>{errTanggalPengaduan}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKotaKabupatenPengaduan(selected.ID) : setKotaKabupatenPengaduan(null)}
                                            id='kotaKabupaten_pengaduans_id'
                                            name='kotaKabupaten_pengaduans_id'
                                            options={kotaKabupatenPengaduanData}
                                            placeholder="Pilih Lokasi Kejadian"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.provinsis.nama + ' - ' + option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kotaKabupatenPengaduanData.find((option) => option.ID === kotaKabupatenPengaduan)}
                                            className={errKotaKabupatenPengaduan && 'is-errorform'}
                                        />
                                        <div className='errorform left-align'>{errKotaKabupatenPengaduan}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setInstansiPengaduan(selected.ID) : setInstansiPengaduan(null)}
                                            id='instansi_pengaduans_id'
                                            name='instansi_pengaduans_id'
                                            options={instansiPengaduanData}
                                            placeholder="Pilih Instansi Tujuan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={instansiPengaduanData.find((option) => option.ID === instansiPengaduan)}
                                        />
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKategoriLaporanSubPengaduan(selected.ID) : setKategoriLaporanSubPengaduan(null)}
                                            id='kategoriLaporanSub_pengaduans_id'
                                            name='kategoriLaporanSub_pengaduans_id'
                                            options={kategoriLaporanSubPengaduanData}
                                            placeholder="Pilih Kategori Laporan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.kategori_laporans.nama + ' - ' + option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kategoriLaporanSubPengaduanData.find((option) => option.ID === kategoriLaporanSubPengaduan)}
                                        />
                                    </div>
                                    <div className='center-align'>
                                        <button className='btn btn-lg btnTambahkan' onClick={kirimPengaduan}>Kirim</button>
                                    </div>
                                </div>
                                <div className='tab-pane' id='kritiksaran' role='tabpanel'>
                                    <div className='form-group'>
                                        <div className={errJudulKritikSaran ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <input
                                                className='form-control form-control-lg formcustom'
                                                id='judul_kritik_saran'
                                                type='text'
                                                name='judul_kritik_saran'
                                                placeholder='Judul Kritik dan Saran'
                                                onChange={(e) => setJudulKritikSaran(e.target.value)}
                                                onKeyDown={(e) => {if(e.key === 'Enter') kirimKritikSaran(e);}}
                                                value={judulKritikSaran}
                                            />
                                        </div>
                                        <div className='errorform left-align'>{errJudulKritikSaran}</div>
                                    </div>
                                    <div className='form-group'>
                                        <div className={errKontenKritikSaran ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <textarea
                                                className='form-control form-control-lg formcustom'
                                                id='konten_kritik_saran'
                                                type='text'
                                                rows='5'
                                                name='konten_kritik_saran'
                                                placeholder='Isi Kritik dan Saran Anda'
                                                onChange={(e) => setKontenKritikSaran(e.target.value)}
                                                value={kontenKritikSaran}
                                            />
                                            <p className='countingtextarea'>{kontenKritikSaran.length} / 250</p>
                                        </div>
                                        <div className='errorform left-align'>{errKontenKritikSaran}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKotaKabupatenKritikSaran(selected.ID) : setKotaKabupatenKritikSaran(null)}
                                            id='kotaKabupaten_kritik_sarans_id'
                                            name='kotaKabupaten_kritik_sarans_id'
                                            options={kotaKabupatenKritikSaranData}
                                            placeholder="Pilih Asal Pelapor"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.provinsis.nama + ' - ' + option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kotaKabupatenKritikSaranData.find((option) => option.ID === kotaKabupatenKritikSaran)}
                                            className={errKotaKabupatenKritikSaran && 'is-errorform'}
                                        />
                                        <div className='errorform left-align'>{errKotaKabupatenKritikSaran}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setInstansiKritikSaran(selected.ID) : setInstansiKritikSaran(null)}
                                            id='instansi_kritik_sarans_id'
                                            name='instansi_kritik_sarans_id'
                                            options={instansiKritikSaranData}
                                            placeholder="Pilih Instansi Tujuan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={instansiKritikSaranData.find((option) => option.ID === instansiKritikSaran)}
                                        />
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKategoriLaporanSubKritikSaran(selected.ID) : setKategoriLaporanSubKritikSaran(null)}
                                            id='kategoriLaporanSub_kritik_sarans_id'
                                            name='kategoriLaporanSub_kritik_sarans_id'
                                            options={kategoriLaporanSubKritikSaranData}
                                            placeholder="Pilih Kategori Laporan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kategoriLaporanSubKritikSaranData.find((option) => option.ID === kategoriLaporanSubKritikSaran)}
                                        />
                                    </div>
                                    <div className='center-align'>
                                        <button className='btn btn-lg btnTambahkan' onClick={kirimKritikSaran}>Kirim</button>
                                    </div>
                                </div>
                                <div className='tab-pane' id='permintaaninformasi' role='tabpanel'>
                                    <div className='form-group'>
                                        <div className={errJudulPermintaanInformasi ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <input
                                                className='form-control form-control-lg formcustom'
                                                id='judul_permintaan_informasi'
                                                type='text'
                                                name='judul_permintaan_informasi'
                                                placeholder='Judul Permintaan Informasi'
                                                onChange={(e) => setJudulPermintaanInformasi(e.target.value)}
                                                onKeyDown={(e) => {if(e.key === 'Enter') kirimPermintaanInformasi(e);}}
                                                value={judulPermintaanInformasi}
                                            />
                                        </div>
                                        <div className='errorform left-align'>{errJudulPermintaanInformasi}</div>
                                    </div>
                                    <div className='form-group'>
                                        <div className={errKontenPermintaanInformasi ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                                            <textarea
                                                className='form-control form-control-lg formcustom'
                                                id='konten_permintaan_informasi'
                                                type='text'
                                                rows='5'
                                                name='konten_permintaan_informasi'
                                                placeholder='Isi Permintaan Informasi Anda'
                                                onChange={(e) => setKontenPermintaanInformasi(e.target.value)}
                                                onKeyDown={(e) => {if(e.key === 'Enter') kirimPermintaanInformasi(e);}}
                                                value={kontenPermintaanInformasi}
                                            />
                                            <p className='countingtextarea'>{kontenPermintaanInformasi.length} / 250</p>
                                        </div>
                                        <div className='errorform left-align'>{errKontenPermintaanInformasi}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKotaKabupatenPermintaanInformasi(selected.ID) : setKotaKabupatenPermintaanInformasi(null)}
                                            id='kotaKabupaten_permintaan_informasis_id'
                                            name='kotaKabupaten_permintaan_informasis_id'
                                            options={kotaKabupatenPermintaanInformasiData}
                                            placeholder="Pilih Asal Pelapor"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.provinsis.nama + ' - ' + option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kotaKabupatenPermintaanInformasiData.find((option) => option.ID === kotaKabupatenPermintaanInformasi)}
                                            className={errKotaKabupatenPermintaanInformasi && 'is-errorform'}
                                        />
                                        <div className='errorform left-align'>{errKotaKabupatenPermintaanInformasi}</div>
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setInstansiPermintaanInformasi(selected.ID) : setInstansiPermintaanInformasi(null)}
                                            id='instansi_permintaan_informasis_id'
                                            name='instansi_permintaan_informasis_id'
                                            options={instansiPermintaanInformasiData}
                                            placeholder="Pilih Instansi Tujuan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={instansiPermintaanInformasiData.find((option) => option.ID === instansiPermintaanInformasi)}
                                        />
                                    </div>
                                    <div className='form-group left-align'>
                                        <Select
                                            onChange={(selected) => selected ? setKategoriLaporanSubPermintaanInformasi(selected.ID) : setKategoriLaporanSubPermintaanInformasi(null)}
                                            id='kategoriLaporanSub_permintaan_informasis_id'
                                            name='kategoriLaporanSub_permintaan_informasis_id'
                                            options={kategoriLaporanSubPermintaanInformasiData}
                                            placeholder="Pilih Kategori Laporan"
                                            isClearable={true}
                                            getOptionLabel={(option) => option.nama}
                                            getOptionValue={(option) => option.ID}
                                            value={kategoriLaporanSubPermintaanInformasiData.find((option) => option.ID === kategoriLaporanSubPermintaanInformasi)}
                                        />
                                    </div>
                                    <div className='center-align'>
                                        <button className='btn btn-lg btnTambahkan' onClick={kirimPermintaanInformasi}>Kirim</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalSuksesLaporComponent
                showmodal={showModalSuksesLapor}
                hidemodal={hideModalSuksesLapor}
                textsukses={textSukses}
                linkbutton={linkButton}
                textbutton={textButton}
            />
        </>
    )

}
export default ModalLapor