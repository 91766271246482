import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const usePoling = (data) => {
    const [polingData, setPolingData] = useState([]);
    const [totalPolingData, setTotalPolingData] = useState(0);
    const [loadingPoling, setLoadingPoling] = useState(true);
    const [errorPoling, setErrorPoling] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorPoling('');
                const API_URL = 'poling?offset=' + data?.offset + '&limit=' + data?.limit + '&cari=' + data?.cari;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        setPolingData(res.data.data);
                        setTotalPolingData(res.data.total_data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorPoling('API poling tidak ada response dari server');
                        } else {
                            setErrorPoling(err.response.data.data);
                        }
                    });
                };
                setLoadingPoling(false);
                fetchData();
            }
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [polingData, totalPolingData, loadingPoling, errorPoling];
}

export default usePoling;