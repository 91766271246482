import { React, useEffect, useState } from 'react';
import { formatTanggalKeDB } from '../../../utils/Utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import id from 'date-fns/locale/id';
import { getYear, getMonth } from 'date-fns';
import range from 'lodash/range';
import Modal from 'react-bootstrap/Modal';
import ModalEktpComponent from './ModalEktp';
import useJenisKelamin from '../../../api/useJenisKelamin';
import useRegister from '../../../api/useRegister';
import { minTanggalLahir } from '../../../utils/Utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';

const ModalRegister = ({ showmodal, hidemodal }) => {
    const years = range(1945, getYear(new Date()) + 1, 1);
    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [nama, setNama] = useState('');
    const [noektp, setNoEktp] = useState('');
    const [tanggallahir, setTanggalLahir] = useState('');
    const [jeniskelamin, setJenisKelamin] = useState(null);
    const [errJenisKelamin, setErrJenisKelamin] = useState('');
    const [jeniskelamindata, setjeniskelamindata] = useState([]);
    const [jenisKelaminData, , , errorJenisKelaminData] = useJenisKelamin(jeniskelamindata);
    const [email, setEmail] = useState('');
    const [noTelp, setNoTelp] = useState('');
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errNama, setErrMessageNama] = useState('');
    const [errNoEktp, setErrMessageNoEktp] = useState('');
    const [errEmail, setErrMessageEmail] = useState('');
    const [errNoTelp, setErrMessageNoTelp] = useState('');
    const [errUser, setErrMessageUser] = useState('');
    const [errPwd, setErrMessagePwd] = useState('');
    const [errTanggalLahir, setErrTanggalLahir] = useState('');
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [showModalEktp, setShowModalEktp] = useState(false);
    const [postRegister, setPostRegister] = useState([]);
    const [errorRegister, successRegister] = useRegister(postRegister);
    const [countSukses, setCountSukses] = useState(0);

    useEffect(() => {
        setShowModalRegister(showmodal);
        if (showmodal === true) {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            setNama('');
            setNoEktp('');
            setEmail('');
            setNoTelp('');
            setTanggalLahir('');
            setJenisKelamin(null);
            setUser('');
            setPwd('');
            setErrMessageNama('');
            setErrMessageNoEktp('');
            setErrMessageEmail('');
            setErrMessageNoTelp('');
            setErrTanggalLahir('');
            setErrJenisKelamin('');
            setErrMessageUser('');
            setErrMessagePwd('');

            const jeniskelaminData = {
                offset: 0,
                limit: 0,
                cari: '',
            }
            setjeniskelamindata(jeniskelaminData);
        }
    }, [showmodal]);

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            return;
        }
        setPasswordType('password');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCountSukses(0);
        setErrorMessage('');
        setSuccessMessage('');
        setErrMessageNama('');
        setErrMessageNoEktp('');
        setErrMessageEmail('');
        setErrMessageNoTelp('');
        setErrTanggalLahir('');
        setErrJenisKelamin('');
        setErrMessageUser('');
        setErrMessagePwd('');
        if (nama.trim().length === 0) {
            setErrMessageNama('Nama belum diisi.');
        }
        else if (noektp.trim().length === 0) {
            setErrMessageNoEktp('Nomor e-KTP belum diisi.');
        }
        else if (email.trim().length === 0) {
            setErrMessageEmail('Email belum diisi.');
        }
        else if (noTelp.trim().length === 0) {
            setErrMessageNoTelp('No. Telp belum diisi.');
        }
        else if (tanggallahir === null) {
            setErrTanggalLahir('Tanggal Lahir belum diisi.');
        }
        else if (jeniskelamin === null) {
            setErrJenisKelamin('Jenis Kelamin belum dipilih.');
        }
        else if (user.trim().length === 0) {
            setErrMessageUser('Nama Pengguna belum diisi.');
        }
        else if (pwd.trim().length === 0) {
            setErrMessagePwd('Katasandi belum diisi.');
        }
        else {
            const usersdata = {
                jenis_kelamins_id: jeniskelamin,
                nama: nama,
                no_ektp: noektp,
                email: email,
                no_telepon: noTelp,
                username: user,
                password: pwd,
                tanggal_lahir: formatTanggalKeDB(tanggallahir),
                status_verifikasi_akun: '0',
            }
            setPostRegister(usersdata);
        }
    }

    useEffect(() => {
        if (errorRegister !== '') {
            setErrorMessage(errorRegister);
            setCountSukses(0);
        } else if (errorJenisKelaminData !== '') {
            setErrorMessage(errorJenisKelaminData)
            setCountSukses(0);
        }
    }, [errorRegister, errorJenisKelaminData]);

    useEffect(() => {
        if (successRegister !== '') {
            setSuccessMessage(successRegister);
            setCountSukses(1);
        }
    }, [successRegister]);

    useEffect(() => {
        if (countSukses === 1 && errorMessage === '' && successMessage !== '') {
            hidemodal();
            setShowModalEktp(true);
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            setNama('');
            setNoEktp('');
            setEmail('');
            setNoTelp('');
            setTanggalLahir('');
            setJenisKelamin(null);
            setUser('');
            setPwd('');
            setErrMessageNama('');
            setErrMessageNoEktp('');
            setErrMessageEmail('');
            setErrMessageNoTelp('');
            setErrTanggalLahir('');
            setErrJenisKelamin('');
            setErrMessageUser('');
            setErrMessagePwd('');
        }
    }, [hidemodal, countSukses, errorMessage, successMessage]);

    return (
        <>
            <Modal
                aria-labelledby='modalLoginLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                show={showModalRegister}
                onHide={hidemodal}
            >
                <div className='modal-header modalheaderregister'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h5 className='submodal-titlelogin'>Siapkan e-KTP Kamu dan pastikan bahwa seluruh informasi e-ktp di bawah ini akurat.</h5>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                        {errorMessage}
                    </div>
                    <div className='form-group'>
                        <div className={errNama ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom'
                                id='nama_register'
                                type='text'
                                name='nama'
                                placeholder='Nama sesuai e-KTP'
                                autoComplete='off'
                                onChange={(e) => setNama(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={nama}
                            />
                        </div>
                        <div className='errorform'>{errNama}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errNoEktp ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom'
                                id='noektp_register'
                                type='text'
                                name='no_ektp'
                                placeholder='Nomor e-KTP'
                                autoComplete='off'
                                onChange={(e) => setNoEktp(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={noektp}
                            />
                        </div>
                        <div className='errorform'>{errNoEktp}</div>
                    </div>
                    <div className='form-group'>
                        <div className='input-group inputgroupmodalcustom'>
                            <DatePicker
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                }) => (
                                    <div
                                        style={{
                                            margin: 10,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <button type='button' className='btn btn-primary btnarrowdatepicker arrowdatepickerleft' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                            {'<'}
                                        </button>
                                        <select
                                            className='form-control form-control-lg formcustom selectdatepicker'
                                            value={getYear(date)}
                                            onChange={({ target: { value } }) => changeYear(value)}
                                        >
                                            {years.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <select
                                            className='form-control form-control-lg formcustom selectdatepicker'
                                            value={months[getMonth(date)]}
                                            onChange={({ target: { value } }) =>
                                                changeMonth(months.indexOf(value))
                                            }
                                        >
                                            {months.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>

                                        <button type='button' className='btn btn-primary btnarrowdatepicker arrowdatepickerright' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                            {'>'}
                                        </button>
                                    </div>
                                )}
                                className={errTanggalLahir ? 'form-control form-control-lg formcustom is-errorform' : 'form-control form-control-lg formcustom'}
                                dateFormat='dd MMMM yyyy'
                                placeholderText='Tanggal Lahir'
                                selected={tanggallahir}
                                locale={id}
                                maxDate={minTanggalLahir(new Date())}
                                closeOnScroll={true}
                                onChange={(tanggallahir) => setTanggalLahir(tanggallahir)} />
                        </div>
                        <div className='errorform'>{errTanggalLahir}</div>
                    </div>
                    <div className='form-group left-align'>
                        <Select
                            onChange={(selected) => selected ? setJenisKelamin(selected.ID) : setJenisKelamin(null)}
                            id='jenis_Kelamins_id'
                            name='jenis_Kelamins_id'
                            options={jenisKelaminData}
                            placeholder="Pilih Jenis Kelamin"
                            isClearable={true}
                            getOptionLabel={(option) => option.nama}
                            getOptionValue={(option) => option.ID}
                            value={jenisKelaminData.find((option) => option.ID === jeniskelamin)}
                            className={errJenisKelamin && 'is-errorform'}
                        />
                        <div className='errorform left-align'>{errJenisKelamin}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errEmail ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom'
                                id='emailektp_register'
                                type='email'
                                name='email'
                                placeholder='Email'
                                autoComplete='off'
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={email}
                            />
                        </div>
                        <div className='errorform'>{errEmail}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errNoTelp ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom'
                                id='notelp_register'
                                type='text'
                                name='no_telepon'
                                placeholder='No. Telp'
                                autoComplete='off'
                                onChange={(e) => setNoTelp(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={noTelp}
                            />
                        </div>
                        <div className='errorform'>{errNoTelp}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errUser ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='username_register'
                                type='text'
                                name='username'
                                placeholder='Nama pengguna'
                                autoComplete='off'
                                onChange={(e) => setUser(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={user}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom'>
                                    <LazyLoadImage src='/img/bx-user-circle-green.svg' alt='profil' />
                                </span>
                            </div>
                        </div>
                        <div className='errorform'>{errUser}</div>
                    </div>
                    <div className='form-group'>
                        <div className={errPwd ? 'input-group inputgroupmodalcustom is-errorform' : 'input-group inputgroupmodalcustom'}>
                            <input
                                className='form-control form-control-lg formcustom formappend'
                                id='katasandi_register'
                                type={passwordType}
                                name='password'
                                placeholder='Katasandi'
                                onChange={(e) => setPwd(e.target.value)}
                                onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(e); }}
                                value={pwd}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text spanformcustom' onClick={togglePassword} style={{ 'cursor': 'pointer' }}>
                                    <LazyLoadImage src='/img/sc-eye.svg' alt='logout' />
                                </span>
                            </div>
                        </div>
                        <div className='errorform'>{errPwd}</div>
                    </div>
                    <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={handleSubmit}>Lanjutkan</button>
                </div>
            </Modal >
            <ModalEktpComponent
                showmodal={showModalEktp}
            />
        </>
    )
}

export default ModalRegister