import { React, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalSuksesComponent from '../sukses/ModalSukses';
import ModalUploadEktpComponent from './ModalUploadEktp';
import ModalUploadSelfieComponent from './ModalUploadSelfie';

const ModalEktp = ({ showmodal }) => {
    const [fotoEktp, setFotoEktp] = useState('');
    const [fotoSelfie, setFotoSelfie] = useState('');
    const [showModalEktp, setShowModalEktp] = useState(false);
    const [showModalUploadEktp, setShowModalUploadEktp] = useState(false);
    const [showModalUploadSelfie, setShowModalUploadSelfie] = useState(false);
    const [showModalSukses, setShowModalSukses] = useState(false);

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('user'));
        setFotoEktp(auth?.data?.foto_ektp);
        setFotoSelfie(auth?.data?.foto_selfie);
    },[]);

    useEffect(() => {
        setShowModalEktp(showmodal);
    },[showmodal]);

    const handleektp = async (e) => {
        e.preventDefault();
        setShowModalEktp(false);
        setShowModalUploadEktp(true);
    }

    const hideModalUploadEktp = () => {
        const auth = JSON.parse(localStorage.getItem('user'));
        setFotoEktp(auth?.data?.foto_ektp);
        setFotoSelfie(auth?.data?.foto_selfie);
        setShowModalEktp(true);
        setShowModalUploadEktp(false);
    }

    const handleselfie = (e) => {
        e.preventDefault();
        setShowModalEktp(false);
        setShowModalUploadSelfie(true);
    }

    const hideModalUploadSelfie = () => {
        const auth = JSON.parse(localStorage.getItem('user'));
        setFotoEktp(auth?.data?.foto_ektp);
        setFotoSelfie(auth?.data?.foto_selfie);
        setShowModalEktp(true);
        setShowModalUploadSelfie(false);
    }

    const handleSubmit = () => {
        setShowModalEktp(false);
        setShowModalSukses(true);
    }

    const hideModalSukses = () => {
        setShowModalSukses(false);
        window.location.href = '/';
    }

    return (
        <>
            <Modal
                aria-labelledby='modalEktpLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                show={showModalEktp}
            >
                <div className='modal-header modalheaderlogin'>
                    <div className='row center-align'>
                        <div className='col-xl-12 col-md-12 col-sm-12'>
                            <h4 className='modal-titlelogin'>Siapkan e-KTP kamu!</h4>
                        </div>
                        <div className='col-xl-12 col-md-12 col-sm-12'>
                            <h5 className='submodal-titlelogin'>Sebagai tahap terakhir pembuatan akun, mohon lakukan verifikasi dengan mengirimkan foto KTP dan Foto selfie dengan e-KTP di tangan menghadap kamera.</h5>
                        </div>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='col-12 center-align'>
                        {fotoEktp ? (
                            <button className='btn btn-lg btn-primary btnmasuklogin mt-0' type='button' onClick={handleektp}>e-KTP</button>
                        ):(
                            <button className='btn btn-lg btn-light btndaftarlogin mt-0' type='button' onClick={handleektp}>e-KTP</button>
                        )}

                        {fotoSelfie ? (
                            <button className='btn btn-lg btn-primary btnmasuklogin mt-0 mb-10' type='button' onClick={handleselfie}>Foto Selfie dengan e-KTP</button>
                        ):(
                            <button className='btn btn-lg btn-light btndaftarlogin mt-0 mb-10' type='button' onClick={handleselfie}>Foto Selfie dengan e-KTP</button>
                        )}
                        
                        {fotoEktp && fotoSelfie ? (
                            <button className='btn btn-lg btn-light btndaftarlogin' type='button' onClick={handleSubmit}>Selesai</button>
                        ):(
                            <button className='btn btn-lg btn-light btndaftarlogin' type='button' disabled>Lanjutkan</button>
                        )}
                    
                    </div>
                </div>
            </Modal>
            <ModalUploadEktpComponent
                showmodal={showModalUploadEktp}
                hidemodal={hideModalUploadEktp}
            />
            <ModalUploadSelfieComponent
                showmodal={showModalUploadSelfie}
                hidemodal={hideModalUploadSelfie}
            />
            <ModalSuksesComponent
                showmodal={showModalSukses}
                hidemodal={hideModalSukses}
                textsukses="Kamu berhasil bergabung dengan SocietyApp!"
            />
        </>
    )
}

export default ModalEktp