import { React, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import usePoling from '../../../api/usePoling';
import ReactPaginate from 'react-paginate';
import { sisaWaktu } from '../../../utils/Utils';
import ModalPolingDetailComponent from '../poling/ModalPolingDetail';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalPoling = ({ showmodal, hidemodal }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [getPolingData, setPolingData] = useState([]);
    const [polingData, totalPolingData, loadingPoling, errorPoling] = usePoling(getPolingData);
    const [showModalPoling, setShowModalPoling] = useState(false);
    const [showModalPolingDetail, setShowModalPolingDetail] = useState(false);
    const [idPoling, setIdPoling] = useState(0);

    const clickHandlerPolingDetail = (id) => {
        setShowModalPoling(false);
        setShowModalPolingDetail(true);
        setIdPoling(id);
    }

    const hideModalPolingDetail = () => {
        setShowModalPoling(true);
        setShowModalPolingDetail(false);

        const auth = JSON.parse(localStorage.getItem('user'));
        setShowModalPoling(showmodal);
        if (showmodal === true) {
            const polingData = {
                offset: ((offset - 1) * postsPerPage),
                limit: postsPerPage,
                cari: '',
                token: auth?.data?.token,
            }
            setPolingData(polingData);
            setPageCount(Math.ceil(totalPolingData / postsPerPage));
            setErrorMessage('');
        }
    }

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('user'));
        setShowModalPoling(showmodal);
        if (showmodal === true) {
            const polingData = {
                offset: ((offset - 1) * postsPerPage),
                limit: postsPerPage,
                cari: '',
                token: auth?.data?.token,
            }
            setPolingData(polingData);
            setPageCount(Math.ceil(totalPolingData / postsPerPage));
            setErrorMessage('');
        }
    }, [offset, totalPolingData, postsPerPage, showmodal]);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
    }

    useEffect(() => {
        if (errorPoling !== '') {
            setErrorMessage(errorPoling);
        }
    }, [errorPoling]);

    return (
        <>
            <Modal
                aria-labelledby='modalPolingLabel'
                data-backdrop='static'
                data-keyboard='false'
                role='dialog'
                tabIndex='-1'
                centered
                dialogClassName="modal-lg"
                show={showModalPoling}
                onHide={hidemodal}
            >
                <div className='modal-header modalheaderprofil'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <p className='modal-titlepoling'>Poling</p>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                        {errorMessage}
                    </div>
                    {loadingPoling === true &&
                        <div className='center-align' colSpan='6'>
                            <LazyLoadImage className='imgloading' src='/img/loading.svg' alt='loading' />
                        </div>
                    }
                    {polingData && polingData.map((poling) => (
                        <div key={poling?.ID}>
                            <button type='button' className='btnnostyle btnlistpoling' onClick={() => clickHandlerPolingDetail(poling?.ID)}>
                                <div className='card card-list-poling'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <p className='waktupoling'>Berakhir {sisaWaktu(poling?.tanggal_selesai)} lagi</p>
                                        </div>
                                        <div className='col-sm-12'>
                                            <p className='kontenpoling'>
                                                {[poling?.judul]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                    {polingData.length === 0 &&
                        <>
                            <div className='btnlistpoling'>
                                <div className='card card-list-poling'>
                                    <p className='kontenpoling center-align'>
                                        Tidak ada poling yang ditampilkan
                                    </p>
                                </div>
                            </div>
                        </>
                    }
                    {pageCount > 1 &&
                        <div className='col-sm-12 center-align'>
                            <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'} />
                        </div>
                    }
                    <div className='right-align'>
                        <button className='btn btn-lg btn-primary btntutuppoling' type='button' onClick={hidemodal}>Tutup</button>
                    </div>
                </div>
            </Modal>
            <ModalPolingDetailComponent
                showmodal={showModalPolingDetail}
                hidemodal={hideModalPolingDetail}
                id={idPoling}
            />
        </>
    )
}

export default ModalPoling