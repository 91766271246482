import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalSuksesLapor = ({ showmodal, hidemodal, linkbutton, textsukses, textbutton }) => {

    return (
        <Modal
            aria-labelledby='modalSuksesLaporLabel'
            data-backdrop='static'
            data-keyboard='false'
            role='dialog'
            tabIndex='-1'
            centered
            show={showmodal}
            onHide={hidemodal}
        >
            <div className='modal-header modalheaderlogin'>
                <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                    <h4 className='modal-titlelogin'>Selamat!</h4>
                </div>
            </div>
            <div className='modal-body'>
                <div className='row'>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        <LazyLoadImage src='/img/success.svg' alt='success' className='imgresponsive' />
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12 center-align'>
                        {parse("<p className='submodal-titlelogin textsukses'>"+textsukses+"</p>")}
                    </div>
                    <div className='col-12 center-align mt-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <a href={linkbutton} className='btn btn-lg btn-lapor' id='buttonmodalsukses'>{textbutton}</a>
                            </div>
                            <div className='col-md-6'>
                                <button className='btn btn-lg btn-tutup' onClick={hidemodal}>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalSuksesLapor