import { React, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalSuksesComponent from '../sukses/ModalSukses';

const ModalKeluarAdmin = ({ showmodal, hidemodal }) => {
    const pengaturanumum = JSON.parse(localStorage.getItem('pengaturanumum'));
    const [showModalSukses, setShowModalSukses] = useState(false);

    const clickHandleSukses = (e) => {
        e.preventDefault();
        hidemodal();
        setShowModalSukses(true);
    }

    const hideModalSukses = () => {
        localStorage.removeItem('admin');
        window.location.href = '/admin/login';
    }

    return (
        <>
            <Modal
                aria-labelledby='modalKeluarLabel'
                role='dialog'
                tabIndex='-1'
                centered
                onHide={hidemodal}
                show={showmodal}
                contentClassName={pengaturanumum?.tema !== 'bawaan' && 'modaldark'}
            >
                <div className='modal-header modalheaderprofil'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titleprofil'>Keluar</h4>
                    </div>
                </div>
                <div className='modal-body'>
                    <p className='textkeluar'>Yakin ingin keluar?</p>
                    <div className='col-12 center-align'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <button className='btn btn-lg btn-light btndaftarlogin' onClick={hidemodal}>Tidak</button>
                            </div>
                            <div className='col-sm-6'>
                                <button className='btn btn-lg btn-primary btnmasuklogin' onClick={clickHandleSukses}>Ya</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalSuksesComponent
                showmodal={showModalSukses}
                hidemodal={hideModalSukses}
                textsukses='Kamu berhasil keluar'
            />
        </>
    )
}

export default ModalKeluarAdmin