import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useKategoriLaporanSub = (data) => {
    const [kotaKabupatenData, setKategoriLaporanSubData] = useState([]);
    const [totalKategoriLaporanSubData, setTotalKategoriLaporanSubData] = useState(0);
    const [loadingKategoriLaporanSub, setLoadingKategoriLaporanSub] = useState(true);
    const [errorKategoriLaporanSub, setErrorKategoriLaporanSub] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorKategoriLaporanSub('');
                const API_URL = 'kategorilaporansub/'+data?.id+'?offset=' + data?.offset + '&limit=' + data?.limit + '&cari=' + data?.cari;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        setKategoriLaporanSubData(res.data.data);
                        setTotalKategoriLaporanSubData(res.data.total_data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorKategoriLaporanSub('API Kategori Laporan Sub tidak ada response dari server');
                        } else {
                            setErrorKategoriLaporanSub(err.response.data.data);
                        }
                    });
                };
                setLoadingKategoriLaporanSub(false);
                fetchData();
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [data]);

    return [kotaKabupatenData, totalKategoriLaporanSubData, loadingKategoriLaporanSub, errorKategoriLaporanSub];
}

export default useKategoriLaporanSub;