import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useJenisKelamin = (data) => {
    const [JenisKelaminData, setJenisKelaminData] = useState([]);
    const [totalJenisKelaminData, setTotalJenisKelaminData] = useState(0);
    const [loadingJenisKelamin, setLoadingJenisKelamin] = useState(true);
    const [errorJenisKelamin, setErrorJenisKelamin] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorJenisKelamin('');
                const API_URL = 'jeniskelamin?offset=' + data?.offset + '&limit=' + data?.limit + '&cari=' + data?.cari;
                const fetchData = async () => {
                    await useAxios.get(API_URL,
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        setJenisKelaminData(res.data.data);
                        setTotalJenisKelaminData(res.data.total_data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorJenisKelamin('API jenis kelamin tidak ada response dari server');
                        } else {
                            setErrorJenisKelamin(err.response.data.data);
                        }
                    });
                };
                setLoadingJenisKelamin(false);
                fetchData();
            }
        }, 500);
        return () => clearTimeout(getData);
    }, [data]);

    return [JenisKelaminData, totalJenisKelaminData, loadingJenisKelamin, errorJenisKelamin];
}

export default useJenisKelamin;