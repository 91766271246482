import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const usePengaturanUmum = () => {
    const [pengaturanUmumData, setPengaturanUmumData] = useState([]);
    const [errorPengaturanUmum, setErrorPengaturanUmum] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            setErrorPengaturanUmum('');
            const API_URL = 'pengaturanumum';
            const fetchData = async () => {
                await useAxios.get(API_URL,
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                ).then(res => {
                    setPengaturanUmumData(res.data.data);
                    localStorage.setItem('pengaturanumum', JSON.stringify(res.data.data));
                }).catch(err => {
                    if (!err.response) {
                        setErrorPengaturanUmum('API pengaturan umum tidak ada response dari server');
                    } else {
                        setErrorPengaturanUmum(err.response.data.data);
                    }
                });
            };
            fetchData();
        }, 1);
        return () => clearTimeout(getData);
    }, []);

    return [pengaturanUmumData, errorPengaturanUmum];
}

export default usePengaturanUmum;