import { React, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import useMasyarakatUploadFotoSelfie from '../../../api/useMasyarakatUploadFotoSelfie';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ModalUploadSelfie = ({ showmodal, hidemodal }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [previewFotoSelfie, setPreviewFotoSelfie] = useState('/img/uploadktp.svg');
    const [fotoSelfie, setFotoSelfie] = useState(null);
    const [tipeFotoSelfie, setTipeFotoSelfie] = useState('');
    const [ukuranFotoSelfie, setUkuranFotoSelfie] = useState('');
    const [errorFotoSelfie, setErrorFotoSelfie] = useState('');
    const [postFotoSelfie, setPostFotoSelfie] = useState([]);
    const [errorUploadFotoSelfie, successUploadFotoSelfie] = useMasyarakatUploadFotoSelfie(postFotoSelfie);
    const [countSukses, setCountSukses] = useState(0);
    const [showModalUploadSelfie, setShowModalUploadSelfie] = useState(false);

    useEffect(() => {
        setShowModalUploadSelfie(showmodal);
        if (showmodal === true) {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
        }
    }, [showmodal]);

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('user'));
        if (auth?.data?.foto_selfie) {
            setPreviewFotoSelfie(auth?.data?.foto_selfie);
        } else {
            setPreviewFotoSelfie('/img/uploadktp.svg');
        }
    }, []);

    const handleUploadFoto = (e) => {
        if (e.target.files[0] !== undefined) {
            setPreviewFotoSelfie(URL.createObjectURL(e.target.files[0]));
            setFotoSelfie(e.target.files[0]);
            setTipeFotoSelfie(e.target.files[0].type);
            setUkuranFotoSelfie(e.target.files[0].size);
        }
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        const auth = JSON.parse(localStorage.getItem('user'));
        if (fotoSelfie === null) {
            setErrorFotoSelfie('Foto belum diisi.');
        } else {
            const usersdata = {
                foto: fotoSelfie,
                tipefoto: tipeFotoSelfie,
                ukuranfoto: ukuranFotoSelfie,
                token: auth?.data?.token,
            }
            setPostFotoSelfie(usersdata);
        }
    }

    useEffect(() => {
        if (errorUploadFotoSelfie !== '') {
            setErrorMessage(errorUploadFotoSelfie);
            setCountSukses(0);
        }
    }, [errorUploadFotoSelfie]);

    useEffect(() => {
        if (successUploadFotoSelfie !== '') {
            setSuccessMessage(successUploadFotoSelfie);
            setCountSukses(1);
        }
    }, [successUploadFotoSelfie]);

    useEffect(() => {
        if (countSukses === 1 && errorMessage === '' && successMessage !== '') {
            setCountSukses(0);
            setErrorMessage('');
            setSuccessMessage('');
            hidemodal();
        }
    }, [hidemodal, countSukses, errorMessage, successMessage]);

    return (
        <Modal
            aria-labelledby='modalUploadSelfieLabel'
            data-backdrop='static'
            data-keyboard='false'
            role='dialog'
            tabIndex='-1'
            centered
            show={showModalUploadSelfie}
            onHide={hidemodal}
        >
            <div className='modal-header modalheaderlogin'>
                <div className='row center-align'>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h4 className='modal-titlelogin'>Unggah KTP</h4>
                    </div>
                    <div className='col-xl-12 col-md-12 col-sm-12'>
                        <h5 className='submodal-titlelogin'>Pilih foto yang jelas dan tidak kabur, serta tulisan dapat dibaca dengan mudah.</h5>
                    </div>
                </div>
            </div>
            <div className='modal-body'>
                <div className={errorMessage ? 'errmsg alert alert-danger' : 'offscreen'} aria-live='assertive' role='alert'>
                    {errorMessage}
                </div>
                <div className='form-group center-align'>
                    <label className={errorUploadFotoSelfie ? 'labeluploadcustom is-errorform' : 'labeluploadcustom'} htmlFor='foto_selfie'>
                        <input
                            id='foto_selfie'
                            type='file'
                            className='uploadfilecustom'
                            onChange={handleUploadFoto}
                            accept='.png, .jpeg, .jpg, .gif, .svg'
                        />
                        <LazyLoadImage className='previewimageupload' src={previewFotoSelfie} alt='upload' />
                    </label>
                    <div className='errorform mb-2'>{errorFotoSelfie}</div>
                </div>
                <button className='btn btn-lg btn-light btndaftarlogin' type='button' disabled={!fotoSelfie} onClick={handlesubmit}>Lanjutkan</button>
            </div>
        </Modal>
    )
}

export default ModalUploadSelfie