import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useLogin = (data) => {
    const [errorLogin, setErrorLogin] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setErrorLogin('');
                const API_URL = 'auth/login';
                const fetchData = async () => {
                    await useAxios.post(API_URL,
                        JSON.stringify({
                            username: data?.username,
                            password: data?.password,
                            tipe: data?.tipeuser,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    ).then(res => {
                        if (data?.tipeuser === 'admin') {
                            if (res.data.data.tipe_users_id === 1 || res.data.data.tipe_users_id === 2) {
                                localStorage.setItem('admin', JSON.stringify(res.data))
                                window.location.href = '/dashboard/admin';
                            } else {
                                setErrorLogin('Nama Pengguna atau Katasandi tidak terdaftar di sistem kami');
                            }
                        } else if (data?.tipeuser === 'user') {
                            if (res.data.data.tipe_users_id === 3) {
                                localStorage.setItem('user', JSON.stringify(res.data));
                                
                                const url = window.location.pathname;
                                if(url === '/lupa-katasandi' || url === '/verifikasi-kode' || url === '/reset-katasandi') {
                                    window.location.href = '/dashboard/member/profil';
                                } else {
                                    window.location = window.location.href;
                                }
                            } else {
                                setErrorLogin('Nama Pengguna atau Katasandi tidak terdaftar di sistem kami');
                            }
                        }
                    }).catch(err => {
                        if (!err.response) {
                            setErrorLogin('API login tidak ada response dari server');
                        } else {
                            setErrorLogin(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorLogin];
}

export default useLogin;