import { useEffect, useState } from 'react';
import useAxios from '../utils/useAxios';

const useMasyarakatUploadFotoSelfie = (data) => {
    const [errorMasyarakatUploadFotoSelfie, setErrorMasyarakatUploadFotoSelfie] = useState('');
    const [successMasyarakatUploadFotoSelfie, setSuccessMasyarakatUploadFotoSelfie] = useState('');

    useEffect(() => {
        const getData = setTimeout(() => {
            if (data !== undefined && data.length !== 0) {
                setSuccessMasyarakatUploadFotoSelfie('');
                const API_URL = 'masyarakat/upload/fotoselfie';
                const formdata = new FormData();
                formdata.append('foto',data?.foto);
                formdata.append('tipe', data?.tipefoto);
                formdata.append('ukuran', data?.ukuranfoto);
                const fetchData = async () => {
                    await useAxios.patch(API_URL,
                        formdata,
                        {
                            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + data?.token },
                        }
                    ).then(res => {
                        localStorage.setItem('user', JSON.stringify(res?.data));
                        setSuccessMasyarakatUploadFotoSelfie(res?.data);
                    }).catch(err => {
                        if (!err.response) {
                            setErrorMasyarakatUploadFotoSelfie('API upload foto selfie tidak ada response dari server');
                        } else if (err.response.status === 401) {
                            localStorage.removeItem('user');
                            window.location.href = '/';
                        } else {
                            setErrorMasyarakatUploadFotoSelfie(err.response.data.data);
                        }
                    });
                };
                fetchData();
            };
        }, 1);
        return () => clearTimeout(getData);
    }, [data]);

    return [errorMasyarakatUploadFotoSelfie, successMasyarakatUploadFotoSelfie];
}

export default useMasyarakatUploadFotoSelfie;